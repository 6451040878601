import { ChangeDetectorRef, Component } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid-community';
import { Country, PaginatedResponse, PaginationRequestParams } from 'app/core/domain/models';
import { QueryService } from '../../../modules/services/query.service';
import { ThirdPartyService } from '../../../modules/master/modules/third-party/modules/third-party/services/third-party.service';
import { isNotEmpty } from '../../../modules/utils/string-utils';
import { isNumber } from '../../../modules/utils/number-utils';

@Component({
  template: `
    <p-autoComplete
      [autoHighlight]="true"
      [field]="'country'"
      [inputStyle]="{ width: '100%' }"
      [suggestions]="suggestions"
      [ngModel]="countrySelection"
      (ngModelChange)="onSelectCountry($event?.id)"
      (keyup.enter)="onSearch($event?.target?.value)"
      (onSelect)="onSelectCountry($event?.id)">
    </p-autoComplete>
  `,
})
export class CustomCountryFilterComponent implements IFilterAngularComp {
  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  countryEntity = Country;
  countryId: number;
  suggestions: any;
  countrySelection: Country;

  constructor(
    private queryService: QueryService,
    private thirdPartyService: ThirdPartyService,
    private cdr: ChangeDetectorRef,
  ) {}

  agInit(params: IFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    // ever return true because backed filter applied
    return isNotEmpty(this.valueGetter(params.node));
  }

  getModel(): any {
    if (isNumber(this.countryId))
      return {
        value: this.countryId,
        filterType: 'customTextFilter',
        type: 'contains' /* Static default value */,
      };
    return null;
  }

  isFilterActive(): boolean {
    // validate and print icon filter on colum header
    return isNumber(this.countryId);
  }

  setModel(model: any): void {
    // model{value} properties must be match with model properties returned on getModel()
    if (model && model.value) {
      this.thirdPartyService.loadCountryId(model.value).then((country: Country) => {
        this.countrySelection = country;
        this.countryId = country && country.id;
      });
    }
  }

  public onSelectCountry(value: number): void {
    if (value !== this.countryId) {
      this.countryId = value;
      // used to apply filters to backend and update rowData (server data)
      this.params.filterModifiedCallback();
      // AgGrid need to know when filter changed and apply new filters (local)
      this.params.filterChangedCallback();
    }
  }

  public onSearch(query): void {
    if (query && isNotEmpty(query)) {
      const pagination = new PaginationRequestParams();
      pagination.q = query;
      pagination.page = 0;
      pagination.columns = 'country'; // only search on field
      this.queryService.querySearch(Country, pagination).then((res: PaginatedResponse) => {
        this.suggestions = res.items;
        this.cdr.markForCheck();
      });
    } else {
      this.countryId = undefined;
      // used to apply filters to backend and update rowData (server data)
      this.params.filterModifiedCallback();
      // AgGrid need to know when filter changed and apply new filters (local)
      this.params.filterChangedCallback();
    }
  }
}
