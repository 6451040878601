import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { delay } from 'rxjs/operators';
import { NkgMenuItem } from './core/components/menu/app-menu.component';
import { Constants } from './core/utils/app.constants';
import { User } from './core/domain/models';
import * as fromRoot from './core/ngrx/reducers/index';
import { NotificationService } from './modules/services/notification.service';
import { nullsafe } from './modules/utils/object-utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss', './app.component.form.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private appSubscription$: Subscription;

  public loggedIn = false;
  public isResponsePending = false;
  public appMenu: NkgMenuItem[] = [];
  public compact = true;
  public rightPanelVisible = false;
  public isOnlyContent = false;

  constructor(
    private store: Store<fromRoot.State>,
    private translateService: TranslateService,
    private constants: Constants,
    private notificationService: NotificationService,
    private primeNgConfig: PrimeNGConfig,
  ) {}

  ngOnInit(): void {
    this.primeNgConfig.ripple = true;
    this.appSubscription$ = this.subscriptionToApp();
  }

  ngOnDestroy(): void {
    this.appSubscription$.unsubscribe();
  }

  private subscriptionToApp(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getCurrentUser),
      this.store.select(fromRoot.getIsAuthenticated),
      this.store.select(fromRoot.getMenu),
      this.store.select(fromRoot.getIsResponsePending).pipe(
        // On Initial load is changed at multiple times
        delay(300),
      ),
      this.store.select(fromRoot.getDisplayAppContentOnly),
    ]).subscribe(([currentUser, isAuthenticate, menu, isResponsePending, displayOnlyAppContent]) => {
      this.loggedIn = isAuthenticate && !!currentUser;
      this.appMenu = menu;
      this.isResponsePending = isResponsePending;
      this.isOnlyContent = displayOnlyAppContent;
      this.onSetLanguage(currentUser);
      this.onSetMenu();
    });
  }

  private onSetMenu(): void {
    if (nullsafe(this.appMenu).length) {
      this.appMenu = Object.assign([], this.appMenu);
      const isTheme: NkgMenuItem = this.appMenu[this.appMenu.length - 1];
      if (isTheme.label !== 'common.labels.themes') {
        this.appMenu.push(
          // Set an additional theme menu item.
          {
            label: 'common.labels.themes',
            icon: 'palette',
            items: [
              {
                label: 'common.labels.theme_default',
                icon: 'brush',
                command: (): void => this.changeTheme('default-nkg'),
              },
              {
                label: 'common.labels.theme_optional',
                icon: 'brush',
                command: (): void => this.changeTheme('blue-grey-nkg'),
              },
            ],
          },
        );
      }
    }
  }

  private onSetLanguage(user?: User): void {
    this.translateService.setDefaultLang(this.constants.DEFAULT_UI_LANGUAGE);
    const uiLanguage: string =
      user?.language?.isoCode || localStorage.getItem(this.constants.UI_LANGUAGE) || this.constants.DEFAULT_UI_LANGUAGE;
    localStorage.setItem(this.constants.UI_LANGUAGE, uiLanguage);
    this.translateService.use(uiLanguage);
    this.translateService.get('primeng').subscribe(res => this.primeNgConfig.setTranslation(res));
  }

  private changeTheme(theme: string): void {
    const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
    const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;

    themeLink.href = `assets/theme/theme-${theme}.min.css`;
    layoutLink.href = `assets/layout/css/layout-${theme}.min.css`;
  }

  public onCompactClick(event: any): void {
    this.compact = !this.compact;
    event.preventDefault();
    // possibly, inner elements might want to resize (like tables / horizontal scrolls)
    window.setTimeout(() => {
      window.dispatchEvent(new Event('resize', {}));
    }, 400); // (animation = 300ms)
  }

  public onMessageItemClicked(): void {
    this.rightPanelVisible = !this.rightPanelVisible;
    if (!this.rightPanelVisible) {
      this.notificationService.markAsRead();
    }
  }

  public onNotificationActionExecuted(): void {
    this.onMessageItemClicked();
  }

  public latestNotificationReadDate(): Date {
    return this.notificationService.getLastReadTime() || new Date(0);
  }
}
