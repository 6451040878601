import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import 'ag-grid-enterprise';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  DefaultColumnVisibility,
  DisabledGridViewColumns,
} from '../../../../shared/grid/customize-table-overlay/customize-table-overlay.component';
import {
  DisabledType,
  PaginatedResponse,
  PaginationMetadata,
  PaginationRequestParams,
  Permission,
} from '../../../../core/domain/models';
import { GridViewNameReference } from '../../../../shared/grid/views/view-type';
import { GridViewsComponent } from '../../../../shared/grid/grid-views/grid-views.component';
import * as fromRoot from '../../../../core/ngrx/reducers';
import { setMasterDataSelectedItem, setMasterModifyMode, setResponsePending } from '../../../../core/ngrx/actions';
import { onGetGridFilters } from '../../../../shared/grid/filter/master-grid-utils';
import { QueryService } from '../../../services/query.service';
import { isNullOrUndefined, nullsafe, Path } from '../../../utils/object-utils';

@Component({
  selector: 'base-master-data-form',
  templateUrl: './base-master-data-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseMasterDataFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() gridViewNameReference: GridViewNameReference = GridViewNameReference.BASE_MASTER_DATA_FORM;
  @Input() gridOptions: GridOptions;
  @Input() gridSelectedRow: any; // Setting via GO on master-data
  @Input() searchParamsForLabel: string[] = [];
  @Input() editMode: boolean;
  @Input() defaultVisibility: DefaultColumnVisibility = {};
  @Input() disabledColumns: DisabledGridViewColumns[] = [];
  @Input() gridColumns: BaseMasterGridCol[];
  @Input() permissionAvailable: Permission[] = [];
  @Input() currentViewMode: FormMasterViewMode = FormMasterViewMode.SPLIT_SCREEN;
  @Input() currentModified: boolean;
  @Input() navigationKey: Path;
  @Input() childDetails: boolean = false;
  @Input() hideToolbar: boolean = false;
  /** only show master-date list items and disabled details mode */
  @Input() staticView: boolean = false;
  // paginationRequestParams
  @Input() paginationRequestParams: PaginationRequestParams;
  @Input() paginationResponse: PaginationMetadata;
  @Input() onlyListMode: boolean = false;
  @Input() sizeToFit: boolean = false;
  @Input() typeFunction: Function; // class model
  // Main user interaction events
  @Output() saveClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() newClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() editClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showRetiredClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSearchEntered: EventEmitter<string> = new EventEmitter<string>();
  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();
  @Output() onQuickFilterSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() gridRowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBackTo: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('masterFormFilter', { static: true }) inputFilterSearch: ElementRef;
  @ViewChild(GridViewsComponent) gridViewsComponent: GridViewsComponent;

  public disableCheckBox = DisabledType;
  public innerWidth: boolean = false;
  public panelRight: boolean = false;
  public panelLeft: boolean = true;
  public loadingData: boolean = false;
  public colDef: ColDef[]; // made this public to work with tests
  public formViewMode = FormMasterViewMode;
  public contextMenuItems: any;

  private filterSearchProps$: Subscription;
  private readonly MEDIUM_SIZE: number = 1025;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    private queryService: QueryService,
    private translateService: TranslateService,
    protected store: Store<fromRoot.State>,
    private messageService: MessageService,
    protected confirmationService: ConfirmationService,
  ) {
    this.contextMenuItems = this.onGetGridContextMenus();
  }

  ngAfterViewInit(): void {
    // grouping target values for any time and send via output
    const term$ = fromEvent<any>(this.inputFilterSearch.nativeElement, 'keyup').pipe(
      map(event => event),
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
    );
    this.filterSearchProps$ = term$.subscribe(query => {
      if (query) this.onFilterTextChanged(query.target.value);
    });
    this.detectScreenSize();
  }

  ngOnInit(): void {
    this.changeViewMode(FormMasterViewMode.SPLIT_SCREEN);
  }

  onChangePage(page: number): void {
    this.changePage.emit(page);
  }

  ngOnDestroy(): void {
    this.filterSearchProps$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if nkg-generic-grid is hidden and row data was updated... then set this flag to true
    if (changes.currentViewMode) {
      this.changeViewMode(this.currentViewMode);
    }
  }

  private onGetGridContextMenus(): any[] {
    return [
      'copy',
      'copyWithHeaders',
      'paste',
      'separator',
      {
        name: this.translateService.instant('grid.labels.csvExport'),
        action: (): void => {
          this.paginationRequestParams.size = this.paginationResponse.totalItems;
          this.paginationRequestParams.page = 0;
          this.store.dispatch(setResponsePending({ isResponsePending: true }));
          // TODO move to master action with NgRx
          this.queryService
            .querySearch(this.typeFunction, this.paginationRequestParams)
            .then((data: PaginatedResponse) => {
              this.paginationRequestParams.size = this.paginationResponse.size;
              this.gridOptions.api.setRowData(data.items);
              this.gridOptions.api.exportDataAsCsv();
              this.store.dispatch(setResponsePending({ isResponsePending: false }));
            });
        },
      },
    ];
  }

  public backTo(): void {
    setTimeout(() => {
      this.setListMode();
      this.onBackTo.emit();
    }, 750);
  }

  public setListMode(): void {
    this.changeViewMode(FormMasterViewMode.LIST_SCREEN);
  }

  private setDetailsMode(): void {
    this.changeViewMode(FormMasterViewMode.DETAILS_SCREEN);
  }

  public setSplitScreenMode(): void {
    this.loadingData = false;
    this.changeViewMode(FormMasterViewMode.SPLIT_SCREEN);
  }

  public onRowClicked(event: any): void {
    /* disabled details mode when staticView is true */
    if (this.currentViewMode === FormMasterViewMode.LIST_SCREEN && !this.staticView) this.setDetailsMode();
    this.gridRowClick.emit(event);
  }

  public onRowDoubleClicked(event: any): void {
    if (this.currentViewMode === FormMasterViewMode.LIST_SCREEN) {
      if (
        this.gridViewsComponent &&
        this.gridViewsComponent.currentGridViewOption &&
        !this.gridViewsComponent.currentGridViewOption.isSaved
      ) {
        this.gridViewsComponent.gridViewName = this.gridViewsComponent.currentGridViewOption.name;
        this.gridViewsComponent.saveViewDialogConfirmed();
      }
      // WARN: Fixed gridView recovery when back to the last master view mode
      setTimeout(() => {
        if (!this.staticView) this.setDetailsMode();
        this.gridRowClick.emit(event);
      }, 500);
    } else {
      this.gridRowClick.emit(event);
    }
  }

  public clickOnSave(): void {
    this.saveClicked.emit();
  }

  public clickEdit(): void {
    this.editMode = true;
    if (this.currentViewMode === FormMasterViewMode.LIST_SCREEN) {
      this.changeViewMode(FormMasterViewMode.SPLIT_SCREEN);
    }
    this.editClicked.emit(this.editMode);
  }

  public clickOnCancel(): void {
    // cancelEvent should be only emitted cancel event
    if (this.currentModified) {
      this.confirmationService.confirm({
        acceptLabel: this.translateService.instant('common.buttons.yes'),
        rejectLabel: this.translateService.instant('common.buttons.no'),
        key: 'discardChangesConfirmationDialog',
        message: this.translateService.instant('masterdata.dialog.discardChangesConfirmation.message'),
        accept: () => {
          this.cancelClicked.emit(true);
          this.onResetDefaultMode();
        },
        reject: () => {
          this.cancelClicked.emit(false);
        },
      });
    } else {
      this.cancelClicked.emit(true);
      this.onResetDefaultMode();
    }
  }

  private onResetDefaultMode(): void {
    if (this.onlyListMode) {
      if (!this.childDetails) {
        this.setListMode();
      } else {
        this.setDetailsMode();
      }
    }
  }

  public clickOnNew(): void {
    if (this.currentViewMode === FormMasterViewMode.LIST_SCREEN && !this.staticView) this.setDetailsMode();
    // navigate to target '_new' and master.base detect the this
    this.router.navigate(['../_new'], { relativeTo: this.route });
    this.newClicked.emit();
    // QC-115 this.onCheckListModeOnly(true);
  }

  public clickOnRetired(event: boolean): void {
    if (!(this.currentViewMode === FormMasterViewMode.DETAILS_SCREEN) && !this.editMode) {
      this.showRetiredClicked.emit(event);
    }
  }

  private onFilterTextChanged(query: string): void {
    // new request on backend
    if (query != this.paginationRequestParams.q) {
      this.onSearchEntered.emit(query);
    }
  }

  protected changeViewMode(mode: FormMasterViewMode): void {
    this.currentViewMode = this.onlyListMode
      ? mode === FormMasterViewMode.SPLIT_SCREEN
        ? FormMasterViewMode.LIST_SCREEN
        : mode
      : mode;
    this.onRedefiningColumns();
    if (this.currentViewMode === FormMasterViewMode.LIST_SCREEN && !this.onlyListMode) this.loadingData = true;
  }

  protected onRedefiningColumns(): void {
    this.colDef = this.gridColumns.map(x => {
      x.colDef.hide =
        this.currentViewMode === FormMasterViewMode.SPLIT_SCREEN
          ? !x.primaryColumn
          : this.currentViewMode === FormMasterViewMode.LIST_SCREEN
            ? !x.default
            : true;
      this.defaultVisibility[x.colDef.colId] = x.default;
      return x.colDef;
    });
  }

  // detecting 1024 size
  @HostListener('window:resize', [])
  detectScreenSize(): void {
    this.innerWidth = window.innerWidth < this.MEDIUM_SIZE;
  }

  public swapTopBar(): void {
    this.panelLeft = !this.panelLeft;
    this.panelRight = !this.panelRight;
  }

  public onChangeGridColumnFilter(): void {
    // TODO: if not posible to remove ngRx feature all class component then dispatch pagination changes here.
    // CASE LIST MODE
    const gridSortingModel = this.gridOptions?.api?.getSortModel();
    const gridFilterModel = this.gridOptions?.api?.getFilterModel();
    if (!isNullOrUndefined(this.gridViewsComponent)) {
      // Gets and sets filter or sort model in the current grid view when the column filter or sort is changed
      this.gridViewsComponent?.onFilterByGrid(gridSortingModel, gridFilterModel);
    } else {
      // CASE SPLIT MODE: When split mode only set PaginationRequestParam with sorting and filter
      // Boolean columns are displayed as “yes” or “no” translated words.
      // So get boolean filters in AgGrid need to compare with yes or no words translated to the current language
      const yesTranslated = this.translateService.instant('common.labels.yes');
      const gridFilters = onGetGridFilters(gridFilterModel, yesTranslated).slice(0, -1);
      const gridSorting = nullsafe(gridSortingModel)[0] as any;
      const finalFilterSort = {
        sort: !isNullOrUndefined(gridSorting) ? `${gridSorting?.colId},${gridSorting?.sort}` : '',
        filter: gridFilters,
      };
      this.onQuickFilterSearch.emit(finalFilterSort);
    }
  }

  public onReject(key: string): void {
    this.messageService.clear(key);
  }

  // TODO: Try to move to abstract master component
  public async onConfirm(key: string): Promise<void> {
    let currentItemId: number = 0;
    this.route.params.subscribe(p => {
      if (p.id != '-' && p.id != '_new' && p.id > 0) {
        currentItemId = +p.id;
      }
    });
    if (currentItemId) {
      const res = await this.queryService.getEntityById(this.typeFunction, currentItemId, this.paginationRequestParams);
      this.store.dispatch(setMasterModifyMode({ isModifyMode: false }));
      this.store.dispatch(setMasterDataSelectedItem({ selectedItem: res }));
      this.messageService.clear(key);
    }
  }
}

export enum FormMasterViewMode {
  SPLIT_SCREEN = 'SPLIT_SCREEN_MODE',
  LIST_SCREEN = 'LIST_SCREEN_MODE',
  DETAILS_SCREEN = 'DETAILS_SCREEN_MODE',
}

export interface BaseMasterGridCol {
  // Enable displaying on split_screen: (true | false)
  primaryColumn?: boolean;
  colDef: ColDef;
  default?: boolean;
}
