import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { Table } from 'primeng/table';
import * as fromRoot from '../../../../core/ngrx/reducers';
import { AbstractEntity } from '../../../../core/domain/models';
import { TimeService } from '../../../../modules/utils/time-service';
import { hasOwnProperty, isNullOrUndefined, mergeImmutable, nullsafe } from '../../../../modules/utils/object-utils';

@Component({
  selector: 'ncs-table-crud',
  templateUrl: './table-crud.component.html',
})
export class TableCrudComponent implements OnDestroy {
  @Input() selectedItem: AbstractEntity;
  @Input() selectedItems: AbstractEntity[];
  @Input() checkbox: boolean = false;
  @Input() items: AbstractEntity[] = []; // An array of objects to display.
  @Input() cols: any[]; // An array of objects to represent dynamic columns
  @Input() editMode: boolean = false;
  @Input() paginator: boolean = false;
  @Input() rows: number = 15;
  @Input() headerDialog: string = '';
  @Input() removeEnable: boolean = true;
  @Input() editEnable: boolean = true;
  @Input() showEnable: boolean = true;
  @Input() addEnable: boolean = true;
  @Input() showOnModal: boolean = true;
  @Input() isDisplayDialog: boolean = false;
  @Input() autoLayout: boolean = true;
  // The Current version of the datatable has an issue when loading is activated.
  // Fixed with changeDetectorRef
  @Input() loading: boolean = false;
  /** Setting data in dialog form */
  @Input() activateShowDialog: boolean = true;
  @Input() selectionMode: string = 'single';
  @Input() exportCsv: boolean = true;
  @Input() pSortableColumnDisabled: boolean = false;
  // create template arrays
  @Input() itemTemplate: any;
  @Input() itemTemplate2: any;
  @Input() itemTemplate3: any;
  /** single || multiple */
  @Input() dialogFormStyle: any;
  @Input() isRefresh: boolean = false;
  @Input() showItemsDeleted: boolean = false;
  @Input() showTable: boolean = true;

  @Output() onSave: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() onClickNew: EventEmitter<any> = new EventEmitter();
  @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRowSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() onCancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onStartEditing: EventEmitter<boolean> = new EventEmitter<boolean>();

  private crudTableSubscription$: Subscription;
  initItems: AbstractEntity[] = [];

  constructor(
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private timeService: TimeService,
    protected store: Store<fromRoot.State>,
  ) {
    this.crudTableSubscription$ = this.crudTableSubscription();
  }

  crudTableSubscription(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getEditMasterModeActive),
      this.store.select(fromRoot.getIsDiscardMasterMode),
    ]).subscribe(([isEditMasterModeActive, isDiscard]) => {
      if (isEditMasterModeActive) {
        this.initItems = this.items;
      }

      if (isDiscard) {
        this.resetItems();
      }
    });
  }

  ngOnDestroy(): void {
    this.crudTableSubscription$.unsubscribe();
  }

  public showDialogToAdd(): void {
    this.showDialog();
    this.onClickNew.emit();
  }

  public save(): void {
    // TODO: item added must be emitting and doesn't add here for some logic
    this.items = this.addItemChild(this.selectedItem);
    this.onSave.emit(this.items);
  }

  public delete(selectedItem: AbstractEntity): void {
    this.rowSelected(selectedItem);
    if (!isNullOrUndefined(selectedItem)) {
      this.confirmationService.confirm({
        acceptLabel: this.translateService.instant('common.buttons.yes'),
        rejectLabel: this.translateService.instant('common.buttons.no'),
        key: 'removeConfirmationDialog',
        message: this.translateService.instant('masterdata.dialog.message.confirm_remove'),
        accept: () => {
          this.selectedItem = selectedItem;
          if (this.selectedItem.id > 0) {
            // Maybe logic moved to root call
            this.selectedItem = mergeImmutable(this.selectedItem, {
              deleted: true,
            });
          }

          this.items =
            this.selectedItem.id < 0
              ? this.items.filter(s => s.id != this.selectedItem.id)
              : this.addItemChild(this.selectedItem);
          this.rowSelected(this.selectedItem);
          this.onDelete.emit(this.items);
        },
        reject: () => {},
      });
    }
  }

  private addItemChild(item: AbstractEntity): AbstractEntity[] {
    if (nullsafe(this.items).length > 0) {
      // replace old item for new item
      return this.items.filter(s => s.id != item.id).concat(item);
    }
    // add item in list empty
    return nullsafe(this.items).concat(item);
  }

  public onStartEdition(selectedItem: AbstractEntity): void {
    this.onStartEditing.emit(true);
    this.onRowSelect(selectedItem);
  }

  public onRowSelect(selectedItem: AbstractEntity): void {
    this.selectedItem = selectedItem;
    this.showDialog();
    this.onRowClick.emit(this.selectedItem);
  }

  public rowSelected(selectedItem: AbstractEntity): void {
    this.selectedItem = selectedItem;
    this.onRowSelected.emit(this.selectedItem);
  }

  public getCellData(row: any, col: any): any {
    if (col && col.field /* col definition property */ && typeof col.field === 'function') {
      return col.field(row);
    }
    if (!isNullOrUndefined(row)) {
      const nestedProperties: string[] = col.field.split('.');
      let value: any = row;

      // Change by Object.entries
      nestedProperties.forEach(prop => {
        if (!isNullOrUndefined(value) && hasOwnProperty(value, prop)) {
          // TODO: use <<date>> type value
          if (prop == 'createdAt') {
            const date = value[prop];
            value = isNullOrUndefined(date) ? value[prop] : this.timeService.formatDateTimeShort(date);
          } else if (hasOwnProperty(col, 'type')) {
            // if there's a type and this is 'date' getting short date
            const { type } = col;
            if (type == 'date') {
              value = this.timeService.formatDateTimeShort(value[prop]);
            } else if (type == 'boolean') {
              value = value[prop]
                ? this.translateService.instant('common.labels.yes')
                : this.translateService.instant('common.labels.no');
            }
          } else {
            value = value[prop];
          }
        } else {
          value =
            hasOwnProperty(col, 'type') && col.type == 'boolean'
              ? this.translateService.instant('common.labels.no')
              : '';
        }
      });
      return this.isValueDate(value);
    }
    return '';
  }

  private isValueDate(value: any): Date {
    return !isNullOrUndefined(value) && value instanceof Date ? this.timeService.formatDateTimeShort(value) : value;
  }

  public hideDialog(): void {
    this.isDisplayDialog = false;
  }

  private showDialog(): void {
    this.isDisplayDialog = this.activateShowDialog;
  }

  public cancel(): void {
    this.hideDialog();
    this.onCancel.emit();
  }

  private resetItems(): void {
    this.items = this.initItems;
  }

  /** Emit refresh event emitter and must be implement method for call */
  public refreshData(): void {
    this.onRefresh.emit();
  }

  exportToCsv(dt: Table): void {
    this.cols.map(col => {
      col.header = this.translateService.instant(col.header);
      return col;
    });

    dt.exportCSV();
  }
}
