import { AfterContentChecked, Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ColDef, GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import {
  Country,
  CuppingSession,
  CuppingSessionSample,
  PaginatedResponse,
  PaginationMetadata,
  Sample,
} from '../../../../../../../../core/domain/models';
import * as fromRoot from '../../../../../../../../core/ngrx/reducers';
import { CuppingSessionService } from '../../../../services/cupping-sesion.service';
import { MasterdataValidationService } from '../../../../../../services/masterdata-validation.service';
import { isNullOrUndefined, mergeImmutable, nullsafe, Path } from '../../../../../../../utils/object-utils';
import { extractEvent } from '../../../../../../../utils/extract-event';
import { ValidationResult } from '../../../../../../services/master-validation';
import { ColDefMap } from '../../../../../../../../shared/search/components/basic-magnifier/magnifier-basic.component';
import { LookupParams } from '../../../../../../../../shared/search/components/lookup-basic/lookup-search.component';
import { NkgGridFactory } from '../../../../../../../../shared/grid/grid.factory';
import {
  authNotificationEmitter,
  setMasterDataSelectedItem,
  setMasterModifyMode,
} from '../../../../../../../../core/ngrx/actions';
import { SampleService } from '../../../../../sample/sample.service';
import { ToastSeverityEnum } from '../../../../../../../../core/ngrx/notification-utils';

@Component({
  selector: 'app-cupping-session-sample',
  templateUrl: './cupping-session-sample.component.html',
})
export class CuppingSessionSampleComponent implements AfterContentChecked {
  @Input() validationResult: ValidationResult;
  @Output() onblur: EventEmitter<Path> = new EventEmitter<Path>();

  sampleSelections: Sample[];
  cuppingSessionSamplesRowData: Sample[] = [];
  gridOptSamples: GridOptions;
  countries: Country[];
  samplePagination: PaginationMetadata;
  sampleSuggestions: SelectItem[];
  columnDefsSamples: ColDef[] = [];
  cuppingSessionSample$: Subscription;
  sampleColDefinition: ColDefMap[];
  currentItem: CuppingSession;
  editMode: boolean;
  protected cuppingSessionStored: CuppingSession;

  constructor(
    protected translationService: TranslateService,
    protected store: Store<fromRoot.State>,
    protected gf: NkgGridFactory,
    protected router: Router,
    protected route: ActivatedRoute,
    protected validationService: MasterdataValidationService,
    protected cuppingSessionService: CuppingSessionService,
    protected sampleService: SampleService,
  ) {
    this.gridOptSamples = this.gf.getDefaultGridOptions(true);
    this.columnDefsSamples = this.getColDefSamples();
    this.cuppingSessionSample$ = this.cuppingSessionSampleSubscription();
    this.sampleColDefinition = this.cuppingSessionService.loadSampleColDefinition(this.countries);
  }

  private cuppingSessionSampleSubscription(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getListCountries),
      this.store.select(fromRoot.getMasterCurrentItem),
      this.store.select(fromRoot.getEditMasterModeActive),
      this.store.select(fromRoot.getCuppingSession),
    ]).subscribe(([countries, currentItem, editMode, cuppingSession]) => {
      this.countries = countries;
      this.currentItem = currentItem;
      this.editMode = editMode;
      this.cuppingSessionStored = cuppingSession;
      if (!isNullOrUndefined(currentItem)) {
        this.loadValidSamples();
      }
    });
  }

  ngAfterContentChecked(): void {
    this.onSetSampleColumnsParameters();
  }

  protected getColDefSamples(): ColDef[] {
    return this.cuppingSessionService.loadSampleColDefs();
  }

  /** agGrid start or end drag event */
  public rowEndEvent(): void {
    const newRowData = [];
    const newCuppingSessionSamples = [];
    this.gridOptSamples.api.forEachNode(node => newRowData.push(node.data));
    const { cuppingSessionSamples } = this.currentItem;
    newRowData.map((sample: Sample, idx: number) => {
      cuppingSessionSamples.some((sessionSample: CuppingSessionSample) => {
        if (!sessionSample.deleted && sample.id == sessionSample.sample.id) {
          newCuppingSessionSamples.push(mergeImmutable(sessionSample, { position: idx }));
          return true;
        }
        return false;
      });
    });
    // needs to add removed items
    newCuppingSessionSamples.concat(cuppingSessionSamples.filter(item => item.deleted));
    this.onItemFieldChange(newCuppingSessionSamples, 'cuppingSessionSamples');
  }

  private onItemFieldChange(event: any, fld?: string): void {
    const upd = {};
    // [QC-39] validate if not empty event
    upd[fld] = event && !Object.keys(event).length ? event : extractEvent(event);
    const newItem: CuppingSession = mergeImmutable(<any>this.getCurrentItem(), upd);
    this.store.dispatch(setMasterDataSelectedItem({ selectedItem: newItem }));
    this.store.dispatch(setMasterModifyMode({ isModifyMode: true }));
  }

  public onItemFieldBlur(fld: Path): void {
    this.onblur.emit(fld);
  }

  protected getCurrentItem(): CuppingSession {
    return this.currentItem;
  }

  public refreshData(): void {
    this.gridOptSamples.api.refreshCells();
  }

  public onRemoveSamples(rmSample: Sample): void {
    let newCuppingSamples: CuppingSessionSample[];
    const rmCuppingSample = this.getCurrentItem().cuppingSessionSamples.find(
      cuppingSample => cuppingSample.sample.id === rmSample.id,
    );
    // CASE 1: Remove new sample items, don't save yet
    if (rmCuppingSample.id < 0) {
      newCuppingSamples = this.getCurrentItem().cuppingSessionSamples.filter(
        cuppingSample => cuppingSample.id !== rmCuppingSample.id,
      );
    } else {
      // CASE 2: Remove items saved and is necessary changes deleted property to true
      // If some sample is present into any related cuppingProcess (coffee cupping) doesn't remove
      const sampleIntoCupping: Set<any> = nullsafe(this.cuppingSessionStored.cuppingProcesses).reduce(
        (a, c) => a.add(c?.sample.id),
        new Set(),
      );
      if (!sampleIntoCupping.has(rmSample.id)) {
        newCuppingSamples = this.getCurrentItem().cuppingSessionSamples.map(cuppingSample => {
          if (cuppingSample.id === rmCuppingSample.id) {
            return mergeImmutable(cuppingSample, {
              position: -1, // For the cuppingSession labels logic
              deleted: true,
            });
          }
          return cuppingSample;
        });
      } else {
        this.store.dispatch(
          authNotificationEmitter({
            severity: ToastSeverityEnum.WARNING,
            summary: this.translationService.instant('notification.cupping_session_remove_sample'),
          }),
        );
        this.loadValidSamples();
        return;
      }
    }
    // After remove need to sorting all items are not deleted
    newCuppingSamples = this.onSortingCuppingSamples(newCuppingSamples);
    this.onItemFieldChange(newCuppingSamples, 'cuppingSessionSamples');
  }

  /** Add new samples to CuppingSession entity
   * @param {Sample} newSelectedSamples[] - Sample array entities */
  public onSelectSamples(newSelectedSamples: Sample[] /* retrieve items saved and new item selected */): void {
    let sourceSavedSamples: CuppingSessionSample[];
    if (nullsafe(this.getCurrentItem().cuppingSessionSamples).length > 0) {
      // avoid creating or to add duplicate cuppingSessionSamples
      sourceSavedSamples = this.getCurrentItem()
        .cuppingSessionSamples.slice()
        .filter(item => !item.deleted);
      const noSampleDuplicates: Sample[] = this.OnRemoveDuplicates(newSelectedSamples, sourceSavedSamples);

      if (noSampleDuplicates.length) {
        let lastIdx = sourceSavedSamples[sourceSavedSamples.length - 1].position; // get last position
        const removeItems: CuppingSessionSample[] = [];
        noSampleDuplicates.map(item => {
          const itemFind = this.onFindDeletedCuppingSessionSample(item.id);
          if (!isNullOrUndefined(itemFind)) {
            removeItems.push(itemFind);
          }
        });
        const deletedItemIds = (removeItems || []).reduce((a, c) => a.add(c.sample.id), new Set());
        const newFilteredItems = noSampleDuplicates.filter(sample => !deletedItemIds.has(sample.id));
        if (removeItems.length) {
          sourceSavedSamples = this.getCurrentItem().cuppingSessionSamples.map(item => {
            if (removeItems.some(rmvItem => rmvItem.sample.id == item.sample.id)) {
              lastIdx++;
              return mergeImmutable(item, {
                deleted: false,
                position: lastIdx,
              });
            }
            return item;
          });
        }

        sourceSavedSamples = sourceSavedSamples.concat(
          <CuppingSessionSample[]>newFilteredItems.map(sample => {
            ++lastIdx;
            return mergeImmutable(this.getNewCuppingSessionSample(), {
              position: lastIdx,
              sample: sample,
            });
          }),
        );
      }
    } else {
      // initial load
      sourceSavedSamples = (newSelectedSamples || []).map((sample, idx) => {
        return mergeImmutable(new CuppingSessionSample(), {
          position: idx,
          sample: sample,
        });
      });
    }
    sourceSavedSamples = this.onSortingCuppingSamples(sourceSavedSamples);
    this.onItemFieldChange(sourceSavedSamples, 'cuppingSessionSamples');
  }

  private OnRemoveDuplicates(source: Sample[], target: CuppingSessionSample[]): Sample[] {
    const savedSamples = (target || []).reduce((a, c) => a.add(c.sample.id), new Set());
    return source.filter(sample => !savedSamples.has(sample.id)); // samples[only new item]
  }

  private onFindDeletedCuppingSessionSample(sampleId: number): CuppingSessionSample {
    const allSavedItems = this.getCurrentItem().cuppingSessionSamples.slice();
    return allSavedItems.find(item => item.deleted && item.sample.id == sampleId);
  }

  /** Create NewCuppingSessionSample entity
   * @return {CuppingSessionSample} cuppingSessionSample */
  private getNewCuppingSessionSample(): CuppingSessionSample {
    this.onSetSampleColumnsParameters();
    return new CuppingSessionSample();
  }

  /** AGGrid Drag logic when fire-up event about */
  private onSetSampleColumnsParameters(): void {
    if (this.gridOptSamples.columnApi && nullsafe(this.gridOptSamples.columnApi.getAllColumns()).length > 0) {
      this.gridOptSamples.api.setSuppressRowDrag(!this.editMode);
    }
  }

  /** Sorting Sample items when detect any change
   * @param {CuppingSessionSample} cuppingSamples[] - Cupping session's sample
   * @return {CuppingSessionSample} cupping session samples sorted */
  private onSortingCuppingSamples(cuppingSamples: CuppingSessionSample[]): CuppingSessionSample[] {
    const deletedItem = cuppingSamples.filter(item => item.deleted);
    return cuppingSamples
      .filter(item => !item.deleted)
      .map((cuppingSample, idx) => {
        return mergeImmutable(cuppingSample, { position: idx });
      })
      .concat(deletedItem);
  }

  /** Load sample suggestions by auto-complete keyup event
   * @param {LookupParams} eventQuery - query and page event */
  public loadSampleSuggestions(eventQuery: LookupParams): void {
    const { query, page } = eventQuery;
    this.sampleService.loadSampleSearches(query, page).then((res: PaginatedResponse) => {
      this.samplePagination = res.__pagination;
      this.sampleSuggestions = nullsafe(res.items).map(item => ({
        label: item.sample,
        value: item,
      }));
    });
  }

  private loadValidSamples(): void {
    const { cuppingSessionSamples } = this.getCurrentItem();
    const validSamples = (cuppingSessionSamples || []).filter(item => !item.deleted).map(item => item.sample);
    this.sampleSelections = validSamples;
    this.cuppingSessionSamplesRowData = validSamples;
    if (this.gridOptSamples && this.gridOptSamples.api) {
      setTimeout(() => {
        this.gridOptSamples.api.sizeColumnsToFit();
      }, 300);
    }
  }
}
