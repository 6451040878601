/**
 * Replace all special keywords/escape tokens such as '.' and '*' in the given string with their
 * equivalent escaped string in a regular expression, such as '\.' and '\*'.
 *
 * @param {string} str the string to replace special tokens in
 * @returns {string} the string to be used as a regular expression
 */
export function replaceStringForRegexp(str: string): string {
  return str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
}
