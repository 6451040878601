import { AgGridColType, FilterGridOptionsEnum } from '../grid.factory';
import { hasOwnProperty, isNullOrUndefined } from '../../../modules/utils/object-utils';
import { isNotEmpty } from '../../../modules/utils/string-utils';

const AVAILABLE_GRID_FILTER = new FilterGridOptionsEnum();

/**
 * Get filters applied on agGrid according column definition
 * @param filters filter model for agGrid
 * @param {string} yesTranslated is used for boolean columns' comparison.
 * Bolean values are setting with yes o no translated for each language
 */
export function onGetGridFilters(filters: any, yesTranslated: string): string {
  let finalQuery = '';
  for (const property in filters) {
    if (hasOwnProperty(filters, property)) {
      const filterSource = <string>filters[property].type;
      const query = filters[property].value;
      let filterType;
      switch (filters[property].filterType) {
        case AgGridColType.NCS_FILTER:
          filterType = onCreateSearchFilterQuery(filterSource);
          finalQuery += `${property}${filterType}${query};`;
          break;
        case AgGridColType.DATE:
          const { dateFrom } = filters[property];
          if (!isNullOrUndefined(dateFrom)) {
            filterType = onCreateSearchFilterQuery(filterSource);
            finalQuery += `${property}${filterType}${dateFrom};`;
          }
          break;
        default:
          // boolean values
          const { values } = filters[property];
          if ((values || []).length > 0) {
            const filterBoolean = values[0] == yesTranslated;
            finalQuery += `${property}==${filterBoolean};`;
          }
          break;
      }
    }
  }
  return finalQuery;
}

function onCreateSearchFilterQuery(filterComparison): string {
  if (isNotEmpty(filterComparison))
    return AVAILABLE_GRID_FILTER.getAllFilters()
      .find(gridFilter => filterComparison == gridFilter.getFilter())
      .getValue();
  return '';
}

export function isFilterChanged(currentFilter, lastFilter): boolean {
  const isDiff = Object.keys(currentFilter).length != Object.keys(lastFilter).length;
  // CASE 1: add new filter on grid
  if (isDiff) return isDiff;

  const keys = Object.keys(currentFilter);
  for (const key of keys) {
    const val = lastFilter[key];
    // CASE 2: same filter items but diff column filters type
    if (!val) return false;

    // CASE 3: same column filters type but diff values
    const isChange =
      currentFilter[key].value != lastFilter[key].value ||
      currentFilter[key].type != lastFilter[key].type ||
      // DATE changes
      currentFilter[key].dateFrom != lastFilter[key].dateFrom ||
      // Boolean filter. Assume filter type 'set' is boolean filters
      JSON.stringify(currentFilter[key].values) != JSON.stringify(lastFilter[key].values);
    if (isChange) return isChange;
  }
  return isDiff;
}
