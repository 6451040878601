import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { hasOwnProperty, isNullOrUndefined, nullsafe } from '../../../../modules/utils/object-utils';
import { FieldValidationResult } from '../../../../modules/master/services/master-validation';

@Component({
  selector: 'nkg-tooltip-target',
  template: '<ng-content></ng-content>',
})
export class TooltipTarget {}

@Component({
  selector: 'nkg-tooltip-content',
  template: '<ng-content></ng-content>',
})
export class TooltipContent {}

export class TooltipMessage {
  key: string;
  params?: any;
}

@Component({
  selector: 'nkg-tooltip',
  template: `
    <div #tooltipTargetContainer class="tooltipTargetContainer" [ngStyle]="targetContainerStyle">
      <!-- show icon on input field -->
      <span *ngIf="tooltipTarget" [ngClass]="iconWrapperClassMap" [ngStyle]="targetStyle">
        <ng-content select="nkg-tooltip-target"></ng-content>
      </span>
      <!-- show icon corresponding to severity if no tooltipTarget is rendered -->
      <span
        *ngIf="!tooltipTarget"
        [class]="'no-target ' + iconClass + ' ' + tooltipTargetIconClass"
        [ngStyle]="iconStyle ? iconStyle : { 'vertical-align': 'middle' }"></span>
    </div>
    <p-overlayPanel
      #tooltipContainer
      appendTo="body"
      *ngIf="tooltipContent || (myTooltipMessages && myTooltipMessages.length > 0)"
      [styleClass]="overlayPanelStyleClasses"
      [dismissable]="false">
      <div [style.maxWidth]="tooltipMaxWidth">
        <ng-content select="nkg-tooltip-content"></ng-content>
        <span
          *ngIf="!tooltipContent && myTooltipMessages && myTooltipMessages.length === 1"
          innerHtml="{{ myTooltipMessages[0].key | translate: myTooltipMessages[0].params }}"></span>
        <ul *ngIf="!tooltipContent && myTooltipMessages && myTooltipMessages.length > 1">
          <li *ngFor="let tooltipMessage of myTooltipMessages">
            {{ tooltipMessage.key | translate: tooltipMessage.params }}
          </li>
        </ul>
      </div>
    </p-overlayPanel>
  `,
})
export class TooltipComponent implements AfterContentInit, OnChanges, OnDestroy {
  tooltipTargetIconClass: string = '';

  @Input() targetHasFocus: boolean = false;
  @Input() fieldWithIcon: boolean;
  @Input() tooltipSeverity: string = 'error';
  @Input() messages: TooltipMessage[] | TooltipMessage | FieldValidationResult;
  @Input() tooltipMaxWidth: string = '600px';
  @Input() targetStyle;
  @Input() targetContainerStyle;
  @Input() tabindex;

  @Input() iconStyle: any;
  @Input() iconClass: string;

  @ContentChild(TooltipTarget) tooltipTarget;
  @ContentChild(TooltipContent) tooltipContent;

  @ViewChild('tooltipTargetContainer') tooltipTargetContainer: ElementRef;
  @ViewChild('tooltipContainer') tooltipContainer: OverlayPanel;

  myTooltipMessages: TooltipMessage[];
  overlayPanelStyleClasses: string = 'nkg-tooltiptext';
  iconWrapperClassMap: any = {};
  onMouseEnterHandler = this.onMouseEnter.bind(this);
  onMouseLeaveHandler = this.onMouseLeave.bind(this);
  onFocusEnterHandler = this.onFocusEnter.bind(this);
  onFocusLeaveHandler = this.onFocusLeave.bind(this);

  private mouseEnterListenerFn: () => void;
  private mouseLeaveListenerFn: () => void;
  private focusEnterListerFn: () => void;
  private focusLeaveListerFn: () => void;
  private displayBecauseOfFocus: boolean = false;

  constructor(
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    let tooltipSeverityChanged: boolean = false;
    if ((changes.messages || changes.targetHasFocus) && this.tooltipTargetContainer) {
      this.myTooltipMessages = [];
      let tmpMsg;
      // if message is fieldValidationResult
      const messageTransform = this.messages as any;
      if (messageTransform && hasOwnProperty(messageTransform, 'invalid')) {
        const fieldValidationResult = <FieldValidationResult>this.messages;
        if (nullsafe(fieldValidationResult.errors).length > 0 && !!messageTransform?.invalid) {
          tmpMsg = fieldValidationResult.errors;
          this.tooltipSeverity = 'error';
          tooltipSeverityChanged = true;
        } else if (nullsafe(fieldValidationResult.warnings).length > 0 && !!messageTransform?.invalid) {
          tmpMsg = fieldValidationResult.warnings;
          this.tooltipSeverity = 'warning';
          tooltipSeverityChanged = true;
        } else {
          this.tooltipSeverity = 'none';
          tooltipSeverityChanged = true;
          tmpMsg = [];
        }
      }
      const tmpMsgKeys = tmpMsg || (Array.isArray(this.messages) ? this.messages : [this.messages]);

      for (const obj of tmpMsgKeys) {
        if (!isNullOrUndefined(obj)) {
          if (typeof obj === 'string') {
            this.myTooltipMessages.push({ key: obj });
          } else if (hasOwnProperty(obj, 'message')) {
            // for errors OR warnings from fieldValidationResult
            this.myTooltipMessages.push({
              key: obj.message,
              params: obj.messageParams,
            });
          } else {
            this.myTooltipMessages.push(<TooltipMessage>obj);
          }
        }
      }
      if (this.tooltipContent || (this.myTooltipMessages && this.myTooltipMessages.length > 0)) {
        this.registerListeners();
        if (this.targetHasFocus) {
          // to show the tooltip during editing
          setTimeout(() => this.showTooltip(), 0);
        }
      } else {
        this.removeListeners();
      }
    }
    if (changes.tooltipSeverity || tooltipSeverityChanged) {
      this.overlayPanelStyleClasses = 'nkg-tooltiptext'; // always
      this.tooltipTargetIconClass = '';
      if (this.tooltipSeverity === 'warning') {
        this.overlayPanelStyleClasses += ' only-warnings';
        this.tooltipTargetIconClass = 'warning-icon';
      } else if (this.tooltipSeverity === 'info') {
        this.overlayPanelStyleClasses += ' info';
        if (!this.tooltipTarget) {
          this.tooltipTargetIconClass = 'fa'; // this class is needed if the component is used without having ng-content
        }
        this.tooltipTargetIconClass += ' info-icon';
      } else if (this.tooltipSeverity === 'help') {
        this.overlayPanelStyleClasses += ' help';
        this.tooltipTargetIconClass = 'fa fa-question-circle';
      } else if (this.tooltipSeverity === 'error') {
        this.overlayPanelStyleClasses += ' error';
        this.tooltipTargetIconClass = 'error-icon';
      } else if (this.tooltipSeverity === 'none') {
        this.overlayPanelStyleClasses += ' none';
      }

      this.iconWrapperClassMap = {
        'invalid-input':
          (this.tooltipSeverity === 'error' || this.tooltipSeverity === 'warning') &&
          this.fieldWithIcon &&
          this.myTooltipMessages &&
          this.myTooltipMessages.length > 0,
        'only-warnings': this.isOnlyWarnings(),
        'has-info':
          this.tooltipSeverity === 'info' &&
          ((this.myTooltipMessages && this.myTooltipMessages.length > 0) || this.tooltipContent),
      };
      if (this.tooltipTargetIconClass) {
        this.iconWrapperClassMap[this.tooltipTargetIconClass] = true;
      }
    }
  }

  private registerListeners(): void {
    if (!this.mouseEnterListenerFn) {
      this.mouseEnterListenerFn = this.renderer.listen(
        this.tooltipTargetContainer.nativeElement,
        'mouseenter',
        this.onMouseEnterHandler,
      );
    }
    if (!this.mouseLeaveListenerFn) {
      this.mouseLeaveListenerFn = this.renderer.listen(
        this.tooltipTargetContainer.nativeElement,
        'mouseleave',
        this.onMouseLeaveHandler,
      );
    }
    const focusElem =
      this.tooltipTargetContainer.nativeElement.querySelector('.setFocus') ||
      this.tooltipTargetContainer.nativeElement.querySelector('#setInputFocus');
    if (focusElem) {
      // To make this tooltip component work with the number input component we need to register the focus events
      // although they are already registered, because the number input component has got two inputs
      if (this.focusEnterListerFn) {
        this.focusEnterListerFn();
        this.focusEnterListerFn = undefined;
      }
      this.focusEnterListerFn = this.renderer.listen(focusElem, 'focus', this.onFocusEnterHandler);
      if (this.focusLeaveListerFn) {
        this.focusLeaveListerFn();
        this.focusLeaveListerFn = undefined;
      }
      this.focusLeaveListerFn = this.renderer.listen(focusElem, 'focusout', this.onFocusLeaveHandler);
    }
  }

  private removeListeners(): void {
    if (this.mouseEnterListenerFn) {
      this.mouseEnterListenerFn();
      this.mouseEnterListenerFn = undefined;
    }
    if (this.mouseLeaveListenerFn) {
      this.mouseLeaveListenerFn();
      this.mouseLeaveListenerFn = undefined;
    }
    if (this.focusEnterListerFn) {
      this.focusEnterListerFn();
      this.focusEnterListerFn = undefined;
    }
    if (this.focusLeaveListerFn) {
      this.focusLeaveListerFn();
      this.focusLeaveListerFn = undefined;
    }
  }

  ngAfterContentInit(): void {
    if (
      this.tooltipTargetContainer &&
      (this.tooltipContent || (this.myTooltipMessages && this.myTooltipMessages.length > 0))
    ) {
      this.registerListeners();
    }
  }

  ngOnDestroy(): void {
    this.removeListeners();
  }

  onMouseEnter(event): void {
    this.showTooltip();
  }

  onMouseLeave(): void {
    if (!this.displayBecauseOfFocus) {
      this.hideTooltip();
    }
  }

  onFocusEnter(): void {
    this.displayBecauseOfFocus = true;
    this.showTooltip();
  }

  onFocusLeave(): void {
    this.displayBecauseOfFocus = false;
    this.hideTooltip();
  }

  showTooltip(): void {
    setTimeout(() => {
      if (this.tooltipContainer) {
        this.tooltipContainer.show({}, this.tooltipTargetContainer.nativeElement);
        this.cd.markForCheck();
      }
    });
  }

  hideTooltip(): void {
    if (this.tooltipContainer) {
      this.tooltipContainer.hide();
      this.cd.markForCheck();
    }
  }

  isOnlyWarnings(): boolean {
    return this.tooltipSeverity === 'warning';
  }
}
