import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MenuItem } from 'primeng/api';

export interface NkgMenuItem extends MenuItem {
  separator?: boolean;
}

@Component({
  selector: '[app-submenu]',
  templateUrl: './app-menu.component.html',
  animations: [
    trigger('children', [
      state(
        'visible',
        style({
          height: '*',
        }),
      ),
      state(
        'hidden',
        style({
          height: '0px',
        }),
      ),
      transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
})
export class AppSubMenuComponent {
  @Input() menuItems: NkgMenuItem[];

  @Input() root: boolean;

  @Input() visible: boolean;

  @Output() itemClicked: EventEmitter<NkgMenuItem> = new EventEmitter();

  activeIndex: number;

  public itemClick(event: Event, item: NkgMenuItem, index: number): boolean {
    // avoid processing disabled sampleThirdPartyTypes
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    if (item.routerLink || item.items || item.command || item.url) {
      this.activeIndex = (this.activeIndex as number) === index ? -1 : index;
    }

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }
    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      event.preventDefault();
    }
  }

  public childClicked(item: NkgMenuItem): void {
    this.itemClicked.emit(item);
  }

  public isActive(index: number): boolean {
    return this.activeIndex === index;
  }
}
