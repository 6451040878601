import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { mergeMap, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import {
  authNotificationEmitter,
  emailAlert,
  emailAlertSuccess,
  errorDataVersioningNotification,
  errorResponse,
  fetchDataMaster,
  fetchDataMasterSuccess,
  fetchGridViews,
  fetchGridViewsSuccess,
  fetchTranslations,
  fetchTranslationsSuccess,
  saveGridView,
  saveMasterData,
  saveMasterDataSuccess,
  saveMasterDataTranslationSuccess,
  setGridView,
  setGridViewSuccess,
  setMasterEditMode,
  setMasterModifyMode,
  setResponsePending,
} from '../actions';
import { CuppingProcess, EmailAdministration, EmailConfirmation, PaginatedResponse, Sample } from '../../domain/models';
import {
  GriViewResponse,
  ViewCustomizationOverlayService,
} from '../../../shared/grid/customize-table-overlay/customize-table-overlay.service';
import { ToastSeverityEnum } from '../notification-utils';
import { QueryService } from '../../../modules/services/query.service';
import { CuppingSessionService } from '../../../modules/master/modules/cupping-session/services/cupping-sesion.service';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';
import { isNotEmpty } from '../../../modules/utils/string-utils';

@Injectable()
export class MasterEffects {
  public constructor(
    protected actions$: Actions,
    protected router: Router,
    protected queryService: QueryService,
    protected translateService: TranslateService,
    protected viewCustomizationService: ViewCustomizationOverlayService,
    protected route: ActivatedRoute,
    protected cuppingSessionService: CuppingSessionService,
  ) {}

  public readonly fetchData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDataMaster),
      switchMap(action =>
        this.queryService.querySearch<PaginatedResponse>(
          action.typeFunction,
          action.paginationRequestParams,
          action.serviceUrl,
          action.columns,
          action.include,
          action.currentId,
          action.useTranslations,
        ),
      ),
      map(res => fetchDataMasterSuccess({ paginationResponse: res })),
      catchError(err => of(errorResponse({ _httpErrorResponse: err }))),
    ),
  );

  public readonly fetchTranslations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchTranslations),
      switchMap(action =>
        this.queryService
          .querySearch<any>(action.typeFunction, null, null, null, null, action.currentId, true)
          .then(data => fetchTranslationsSuccess({ translations: data }))
          .catch(error => errorResponse({ _httpErrorResponse: error })),
      ),
    ),
  );

  public readonly saveData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(saveMasterData),
      switchMap(action =>
        this.queryService
          .saveEntity(action.data, action.typeFunction, action.paginationRequestParams, action.isoCode)
          .then((res: any) => {
            if (action.isoCode)
              // Case 1: Save master-data translation
              return saveMasterDataTranslationSuccess({
                typeFunction: action.typeFunction,
                data: res,
                redirectUrl: action.redirectUrl,
                isoCode: action.isoCode,
              });
            // Case 2: Save master-data
            return saveMasterDataSuccess({
              typeFunction: action.typeFunction,
              data: res,
              redirectUrl: action.redirectUrl,
              paginationRequestParams: action.paginationRequestParams,
              dynamicUrl: action.dynamicUrl,
            });
          })
          .catch(error => errorResponse({ _httpErrorResponse: error })),
      ),
    ),
  );

  public readonly saveData2$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(saveMasterData),
      map(() => setResponsePending({ isResponsePending: true })),
    ),
  );

  public readonly fetch2$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDataMaster),
      map(() => setResponsePending({ isResponsePending: true })),
    ),
  );

  public readonly saveDataSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveMasterDataSuccess),
        filter(itemSaved => !isNullOrUndefined(itemSaved)),
        switchMap(currentMaster => {
          // Avoid setting undefined values to SelectCurrentItem when occur versioning error
          if (isNotEmpty(currentMaster.redirectUrl))
            this.router.navigateByUrl(currentMaster.redirectUrl + currentMaster.data.id).then();

          // CASE 1: CurrentItem is a CuppingProcess entity don't fetch master data. Only recovery current data and change response pending
          if (currentMaster.typeFunction == CuppingProcess || currentMaster.typeFunction == Sample) {
            if (!isNullOrUndefined(currentMaster?.dynamicUrl))
              this.router.navigateByUrl(
                this.onCreateCuppingProcessUrlAfterSave(currentMaster.dynamicUrl, currentMaster.data.id),
              );
          }
          // CASE 2: Master currentItem
          return [
            fetchDataMaster({
              typeFunction: currentMaster.typeFunction,
              paginationRequestParams: currentMaster.paginationRequestParams,
            }),
            setResponsePending({ isResponsePending: false }),
          ];
        }),
      ),
    { dispatch: true },
  );

  public readonly fetchDataSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDataMasterSuccess),
      map(() => setResponsePending({ isResponsePending: false })),
    ),
  );

  public readonly emailAlert$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(emailAlert),
      switchMap(action =>
        this.queryService
          .emailAlert<EmailAdministration>(action.emailAlert, action.typeFunction)
          .then((resSaved: EmailConfirmation) =>
            emailAlertSuccess({
              typeFunction: action.typeFunction,
              data: resSaved,
              redirectUrl: action.redirectUrl,
            }),
          )
          .catch(error => errorResponse({ _httpErrorResponse: error })),
      ),
    ),
  );

  public readonly emailAlertSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(emailAlertSuccess),
        map(() =>
          authNotificationEmitter({
            summary: 'masterdata.label.successfulAlert',
            severity: ToastSeverityEnum.SUCCESS,
          }),
        ),
      ),
    { dispatch: false },
  );

  public readonly emailAlert2$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(emailAlert),
      map(() => setResponsePending({ isResponsePending: true })),
    ),
  );

  public readonly emailAlertSuccess2$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(emailAlertSuccess),
        map(() => setResponsePending({ isResponsePending: false })),
      ),
    { dispatch: true },
  );

  public readonly error$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(errorResponse),
        switchMap(data => {
          // Catch versioning error
          const { _httpErrorResponse: errorRes } = data;
          if (
            errorRes instanceof HttpErrorResponse &&
            errorRes.status === 500 &&
            errorRes.error[0].fieldPath === 'TransactionSystemException'
          ) {
            return [
              errorDataVersioningNotification({ errorDataVersioningVisible: true }),
              setResponsePending({ isResponsePending: false }),
            ];
          }
          return [
            errorDataVersioningNotification({ errorDataVersioningVisible: false }),
            setResponsePending({ isResponsePending: false }),
            setMasterModifyMode({ isModifyMode: true }),
            setMasterEditMode({ isEditMode: true }),
          ];
        }),
      ),
    { dispatch: true },
  );

  public readonly saveDataTranslateSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveMasterDataTranslationSuccess),
        switchMap(action => [
          fetchTranslations({ typeFunction: action.typeFunction, currentId: action.data.id }),
          setResponsePending({ isResponsePending: false }),
        ]),
      ),
    { dispatch: true },
  );

  public readonly saveDataSuccessMessage$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveMasterDataSuccess),
        map(() =>
          authNotificationEmitter({
            summary: 'notification.success_saved',
            severity: ToastSeverityEnum.SUCCESS,
          }),
        ),
      ),
    { dispatch: true },
  );

  public readonly saveDataSuccessTranslateMessage$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveMasterDataTranslationSuccess),
        map(() =>
          authNotificationEmitter({
            summary: 'notification.success_saved',
            severity: ToastSeverityEnum.SUCCESS,
          }),
        ),
      ),
    { dispatch: true },
  );

  public readonly fetchGridView$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchGridViews),
      mergeMap(action => this.viewCustomizationService.loadGridView(action.gridViewReference, action.initialGridView)),
      map(data => {
        if (!(data.gridViews || []).length && !isNullOrUndefined(data.initialGrid))
          return saveGridView({ gridView: data.initialGrid, gridViewReference: data.reference });
        // The goal is that first time not load master-data until fetchGridView action is complete
        // and get request params from currentGridView
        return fetchGridViewsSuccess({ gridViews: data.gridViews });
      }),
    ),
  );

  public readonly setCurrentGridView$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(setGridView),
      map(action => setGridViewSuccess({ gridView: action.gridView })),
    ),
  );

  public readonly saveGridView$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(saveGridView),
      switchMap(action =>
        this.viewCustomizationService.saveGridView(action.gridView, action.gridViewReference, action.forceRefresh),
      ),
      switchMap((data: GriViewResponse) => [
        setGridView({
          gridView: JSON.parse(data.res.value),
          yesTranslated: this.translateService.instant('common.labels.yes'),
          id: data.res.id,
        }),
        fetchGridViews({ gridViewReference: data.viewReference }),
      ]),
    ),
  );

  public readonly showVersioningNotification$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(errorDataVersioningNotification),
        map(action => {
          if (action.errorDataVersioningVisible) {
            authNotificationEmitter({
              key: 'versioning_error_notification',
              sticky: true,
              severity: ToastSeverityEnum.INFO,
              summary: 'notification.error_occurred',
              detail: 'notification.versioning_error',
            });
          }
        }),
      ),
    { dispatch: false },
  );

  private onCreateCuppingProcessUrlAfterSave(preUrl: any, cuppingProcessId: number): UrlTree {
    return this.router.createUrlTree([preUrl.urlBase], {
      relativeTo: this.route,
      queryParams: {
        cupping_process_id: cuppingProcessId,
        type: preUrl.type,
        onlyContent: preUrl.onlyContent,
      },
    });
  }
}
