import { ModuleWithProviders, NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { NumberRendererComponent } from 'app/shared/grid/renderer/number-renderer.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CommonModule, DecimalPipe, NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PickListModule } from 'primeng/picklist';
import { NumberInlineCellEditorComponent } from './number-inline-cell-editor/number-inline-cell-editor.component';
import { CustomizeTableOverlayComponent } from './customize-table-overlay/customize-table-overlay.component';
import { PeriodRendererComponent } from './renderer/period-renderer.component';
import { NkgGridFactory } from './grid.factory';
import { SharedModule } from '../shared.module';
import { GridComponent } from './grid.component';
import { DateRendererComponent } from './renderer/date-renderer.component';
import { ActionRendererComponent } from './renderer/action-renderer.component';
import { NkgGenericGridComponent } from './nkg-generic-grid.component';
import { DateCellEditorComponent } from './date-inline-cell-editor/date-inline-cell-editor.component';
import { TextCellEditorComponent } from './text-inline-cell-editor/text-inline-cell-editor.component';
import { TextTooltipCellRendererComponent } from './tooltip/TextTooltipCellRendererComponent';
import { GridViewsComponent } from './grid-views/grid-views.component';
import { BooleanInlineCellEditorComponent } from './boolean-inline-cell-editor/boolean-inline-cell-editor.component';
import { RemoveBtnStandardEquivalenceComponent } from './remove-btn-inline-cell-editor/remove-btn-standard-equivalence.component';
import { RemoveBtnStandardDefinitionOptComponent } from './remove-btn-inline-cell-editor/remove-btn-standard-definition-opt.component';
import { AddScoreDescriptionComponent } from './add-score-description-cell-editor/add-score-description.component';
import { CustomDateComponent } from './filter/custom-date-filter/custom-date.component';
import { CustomTextFilterComponent } from './filter/custom-text-filter/custom-text-filter.component';
import { CustomNumberFilterComponent } from './filter/custom-number-filter/custom-number-filter.component';
import { CustomCountryFilterComponent } from './filter/custom-country-filter.component';
import { SharedBasicModule } from '../basic-shared-module/shared-basic.module';
import { NcsPaginationComponent } from './pagination/pagination.component';
import { DatePipeProxy } from '../pipes/date-pipe';

/**
 * This module provides common grid functionality.
 *
 * The module exports the GridComponent, which is a wrapper for ag-grid components and provides common functionality
 * most grids of the application will need on top of what ag-grid provides. Therefore, every ag-grid in the application
 * should be wrapped into a GridComponent.
 *
 * This module also re-exports AgGridModule itself. This way only the GridModule has to be imported when a grid needs to
 * be implemented in a component.
 *
 * Furthermore the module defines several common Renderer- and CellEditorComponents that can be used in grids throughout
 * the application. They are not exported but defined as entry components, so that they can be instantiated when needed
 * by ag-grid.
 */
@NgModule({
  imports: [
    AgGridModule.withComponents([
      BooleanInlineCellEditorComponent,
      CustomTextFilterComponent,
      CustomNumberFilterComponent,
      CustomCountryFilterComponent,
    ]),
    SharedBasicModule,
    SharedModule,
    ScrollPanelModule,
    NgStyle,
    DecimalPipe,
    CommonModule,
    TranslateModule,
    ButtonModule,
    DialogModule,
    InputTextModule,
    FormsModule,
    AutoCompleteModule,
    PickListModule,
  ],
  declarations: [
    CustomizeTableOverlayComponent,
    GridComponent,
    // renderer and inline editor components
    ActionRendererComponent,
    PeriodRendererComponent,
    DateRendererComponent,
    NumberRendererComponent,
    NumberInlineCellEditorComponent,
    NkgGenericGridComponent,
    DateCellEditorComponent,
    BooleanInlineCellEditorComponent,
    TextCellEditorComponent,
    // PartialTextFloatingFilter,
    TextTooltipCellRendererComponent,
    GridViewsComponent,
    RemoveBtnStandardEquivalenceComponent,
    RemoveBtnStandardDefinitionOptComponent,
    AddScoreDescriptionComponent,
    CustomDateComponent,
    CustomTextFilterComponent,
    CustomNumberFilterComponent,
    CustomCountryFilterComponent,
    NcsPaginationComponent,
    DatePipeProxy,
  ],
  exports: [
    AgGridModule,
    CustomizeTableOverlayComponent,
    GridComponent,
    NkgGenericGridComponent,
    GridViewsComponent,
    NcsPaginationComponent,
    DatePipeProxy,
  ],
})
export class GridModule {
  static forRoot(): ModuleWithProviders<GridModule> {
    return {
      ngModule: GridModule,
      providers: [NkgGridFactory],
    };
  }
}
