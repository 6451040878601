import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe, NgClass, NgForOf, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NcsTextareaBasicComponent } from './components/textarea-basic/textarea-basic.component';
import { NcsTextBasicComponent } from './components/text-basic/text-basic.component';
import { NcsSelectBoxComponent } from './components/ncs-select-box/ncs-select-box.component';
import { DateBasicComponent } from './components/date-basic/date-basic.component';
import { CheckboxBasicComponent } from './components/checkbox-basic/checkbox-basic.component';
import { NcsCheckboxComponent } from './components/checkbox/ncs-checkbox.component';
import { NcsButtonBasicComponent } from './components/button-basic/button-basic.component';
import { NcsTextSearchComponent } from './components/text-basic/text-search.component';
import { TranslationLanguageComponent } from './components/text-basic/translation-language/translation-language.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { ErrorTooltipComponent } from './components/tooltip/error-tooltip.component';
import { DialogBasicComponent } from './components/dialog-basic/dialog-basic.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { TooltipComponent, TooltipContent, TooltipTarget } from './components/tooltip/tooltip.component';
import { CuppingProcessReportBtnComponent } from './components/table-crud/cupping-process-report-btn.component';
import { TableCrudComponent } from './components/table-crud/table-crud.component';
import { RestrictAccessDirective } from '../../core/directives/RestrictAccessDirective';

@NgModule({
  declarations: [
    NcsTextareaBasicComponent,
    NcsTextBasicComponent,
    NcsSelectBoxComponent,
    DateBasicComponent,
    CheckboxBasicComponent,
    NcsCheckboxComponent,
    NcsButtonBasicComponent,
    NcsTextSearchComponent,
    TranslationLanguageComponent,
    NumberInputComponent,
    ErrorTooltipComponent,
    DialogBasicComponent,
    DateInputComponent,
    TooltipComponent,
    TooltipContent,
    TooltipTarget,
    CuppingProcessReportBtnComponent,
    TableCrudComponent,
    RestrictAccessDirective,
  ],
  exports: [
    NcsTextareaBasicComponent,
    NcsTextBasicComponent,
    NcsSelectBoxComponent,
    DateBasicComponent,
    CheckboxBasicComponent,
    NcsCheckboxComponent,
    NcsButtonBasicComponent,
    NcsTextSearchComponent,
    TranslationLanguageComponent,
    NumberInputComponent,
    ErrorTooltipComponent,
    DialogBasicComponent,
    DateInputComponent,
    TooltipComponent,
    TooltipContent,
    TooltipTarget,
    CuppingProcessReportBtnComponent,
    TableCrudComponent,
    RestrictAccessDirective,
  ],
  imports: [
    TranslateModule,
    NgIf,
    NgClass,
    ButtonModule,
    NgTemplateOutlet,
    NgStyle,
    InputTextareaModule,
    InputTextModule,
    TooltipModule,
    FormsModule,
    DropdownModule,
    DialogModule,
    CheckboxModule,
    TableModule,
    NgForOf,
    CalendarModule,
    DatePipe,
    OverlayPanelModule,
  ],
})
export class SharedBasicModule {}
