import { createReducer, on } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import * as ApprovalActionTypes from '../actions/approval.action';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';

export enum ApprovalStatus {
  SUCCESS,
  FAILED,
  NONE,
}

export interface ApprovalState {
  session: string;
  approved: boolean;
  comment: string;
  backendPending: boolean;
  isError: boolean;
  finished: boolean;
  errorMessage: string;
  promiseError: HttpErrorResponse;
  sample: string;
  validationStatus: ApprovalStatus;
  approvingStatus: ApprovalStatus;
}

const initialSampleApprovalState: ApprovalState = {
  session: null,
  approved: true,
  comment: '',
  backendPending: false,
  isError: false,
  finished: false,
  errorMessage: null,
  promiseError: null,
  sample: null,
  validationStatus: ApprovalStatus.NONE,
  approvingStatus: ApprovalStatus.NONE,
};

export const approvalReducer = createReducer(
  initialSampleApprovalState,
  on(
    ApprovalActionTypes.approveDispatch,
    (state: ApprovalState, { credentials }): ApprovalState => ({
      ...state,
      ...credentials,
      backendPending: true,
      finished: false,
      promiseError: null,
      approvingStatus: ApprovalStatus.NONE,
    }),
  ),
  on(
    ApprovalActionTypes.approvalSuccess,
    (state: ApprovalState, { response }): ApprovalState => ({
      ...state,
      ...response,
      backendPending: false,
      promiseError: null,
      finished: true,
      approvingStatus: ApprovalStatus.SUCCESS,
    }),
  ),
  on(
    ApprovalActionTypes.approvingFailed,
    (state: ApprovalState, { errorResponse }): ApprovalState => ({
      ...state,
      promiseError: errorResponse,
      backendPending: false,
      finished: false,
      sample: null,
      approvingStatus: ApprovalStatus.FAILED,
    }),
  ),
  on(
    ApprovalActionTypes.validateSession,
    (state: ApprovalState, { session }): ApprovalState => ({
      ...state,
      session: session,
      backendPending: true,
      validationStatus: ApprovalStatus.NONE,
      promiseError: null,
    }),
  ),
  on(
    ApprovalActionTypes.sessionValidated,
    (state: ApprovalState, { credential }): ApprovalState => ({
      ...state,
      ...credential,
      backendPending: false,
      finished: false,
      sample: isNullOrUndefined(credential) ? null : credential.sample,
      validationStatus: ApprovalStatus.SUCCESS,
      promiseError: null,
    }),
  ),
  on(
    ApprovalActionTypes.sessionValidateFailed,
    (state: ApprovalState, { errorResponse }): ApprovalState => ({
      ...state,
      backendPending: false,
      validationStatus: ApprovalStatus.FAILED,
      sample: null,
      approved: true,
      promiseError: errorResponse,
    }),
  ),
);

export const getSampleApprovalState = (state: ApprovalState): ApprovalState => state;
