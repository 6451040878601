import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEnGB from '@angular/common/locales/en-GB';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { take } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { NgrxModule } from './core/ngrx/ngrx-manage.module';
import { MsalRedirectComponent } from './msal-redirect.component';
import { AppComponent } from './app.component';
import { AuthorizationModule } from './modules/authorization/authorization.module';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class TranslateHandler implements MissingTranslationHandler {
  handle(): void {}
}

export function appInitializerFactory(translateService: TranslateService, injector: Injector): () => Promise<any> {
  // tslint:disable-next-line:no-any
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        of(translateService.use(localStorage.getItem('__ui_language__') || window.navigator.language)) // here u can change language loaded before reander enything
          .pipe(take(1))
          .subscribe({
            error: e => console.error(e),
            complete: () => resolve(null),
          });
      });
    });
}

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    AuthorizationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
      missingTranslationHandler: [{ provide: MissingTranslationHandler, useClass: TranslateHandler }],
    }),
    NgrxModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    ToastModule,
  ],
  declarations: [AppComponent, MsalRedirectComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
registerLocaleData(localeEn);
registerLocaleData(localeEnGB);
registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
