import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SelectItem } from 'primeng/api';
import { ColumnsType, GridSelectionMode } from '../../../grid/nkg-generic-grid.component';
import { replaceStringForRegexp } from '../../../string-utils';
import { PaginationMetadata, PaginationRequestParams } from '../../../../core/domain/models';
import { fetchSearchGenericItems, setResponsePending } from '../../../../core/ngrx/actions';
import * as fromRoot from '../../../../core/ngrx/reducers';
import { ColDefMap } from '../basic-magnifier/magnifier-basic.component';
import { LookupParams } from '../lookup-basic/lookup-search.component';
import { hasOwnProperty, isNullOrUndefined, nullsafe } from '../../../../modules/utils/object-utils';
import { isNotEmpty } from '../../../../modules/utils/string-utils';
import { NcsBaseBasicComponent } from '../../../basic-shared-module/components/base-basic/base-basic.component';

@Component({
  selector: 'ncs-generic-search',
  templateUrl: './search-generic.component.html',
})

/**
 * Example for details mode
 * the btnMagnifier should be true
 * columnsNamesGeneric, example ['gridViewName', 'country_code', 'location_code','subdiv']
 * disabledColumnsGeneric, example ['gridViewName', 'country_code']
 * typeEntitySearch, example Location(definitions on models)
 * selection, is value of a component. Not required
 * onSelected event, returned {selection: event, valid:true} or {selection:event, valid:false}
 */
// TODO:on mode multi selection there is a bug when changed content:Temporary solution is markForCheck in root component.
// Bug is issue of PrimeNG
export class NcsGenericSearch extends NcsBaseBasicComponent implements OnInit, OnChanges, OnDestroy {
  @Output() onSelected: EventEmitter<any> = new EventEmitter(); // {selection: value, display: getValueDisplay(value)} || internalSelection(value)
  @Output() onFocus: EventEmitter<any> = new EventEmitter();
  @Output() onUnSelected: EventEmitter<any> = new EventEmitter();
  @Output() onSelectedRows: EventEmitter<any> = new EventEmitter(); // getSelections rows of grid

  /** Activate the button to display the results in the grid. */
  @Input() btnMagnifier: boolean = false;
  /** Tooltip help */
  @Input() labelInfo: string;
  @Input() internalSelection: SelectItem[];
  @Input() focusable: boolean = true;
  /** Value used and shared by lookup and grid search */
  @Input() targetValue: string = '';
  /** Field for display values into p-autocomplete */
  @Input() fieldDisplay: string[];
  /** Selection mode: multiple or single */
  @Input() multiple: boolean = false;
  /** Entity type */
  @Input() typeEntitySearch: Function;
  /** Url service for backend */
  @Input() serviceUrl: string = null;
  /** Remove button search icon and to replace for this label */
  @Input() searchNameBtn: string;
  /** Disables input search and activates only the grid detail search */
  @Input() onlyGrid: boolean = false; // Enable or disable input search
  @Input() filterBySplit: boolean;
  @Input() genericSearchGridColumnType: ColumnsType = ColumnsType.COL_DEF;
  /** Setting title property of grid */
  @Input() titleGrid: string;
  /** Use when needs nested functions or complex logic into column definitions
   * Otherwise, use paginationRequestParams
   * */
  @Input() colDefinition: ColDefMap[];
  @Input() placeholder: string = 'grid.labels.search_and_select';
  /** Params for services of search to backend */
  @Input() paginationRequestParams: PaginationRequestParams = new PaginationRequestParams();
  // properties for local logic
  public pagination: PaginationMetadata;
  public displayGrid: boolean = false; // activate grid
  public suggestions: SelectItem[]; // words suggestions for autocomplete
  public gridSelectionMode = GridSelectionMode;
  public colDefArray: string[];

  private query: string; // word typed and search matching on the server with any entity
  private lastTargetValue: string = ''; // Used for recovery last query found on lookup or grid feature.
  private lastInternalSelection: any; // last internal value
  private wasSelected: boolean = false;
  // When didn't provide colDefinition param for the column definitions,
  // this is used to render text columns in AgGrid

  private searchResult: any; // used for stored suggestion
  private genericSearchSubscription$: Subscription;

  constructor(
    private cd: ChangeDetectorRef,
    private store: Store<fromRoot.State>,
  ) {
    super();
    this.genericSearchSubscription$ = this.storeToProps();
  }

  // start when component is init for first time and when value is changed
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      /**
       * WARNING
       * Master-data case: when select any item, this value preserve (value)
       * need setting to undefined
       */
      if (isNullOrUndefined(this.value)) {
        this.internalSelection = this.value = [];
        return;
      }
      if (this.value) {
        this.suggestions = undefined;
        // single select mode, value should not be object arrays
        const internal = [].concat(this.value);
        // Line commented below is functional for convert object arrays to object
        // if you use this lines,
        // handle internalSelection like Object and not like object array.
        // Change template and onBlur
        // internal = internal.map((model) => this.modelToSelectItem(model));
        // this.internalSelection = this.multiple? internal: internal[0];
        this.internalSelection = internal.map(model => this.modelToSelectItem(model));
      }
      if (this.internalSelection != this.lastInternalSelection && this.btnMagnifier) {
        this.targetValue = '';
      }
      // WARN: Force detection changes on multi-option
      if (this.multiple)
        setTimeout(() => {
          this.cd.markForCheck();
        });
    }
  }

  ngOnInit(): void {
    // For generic search, this component use NgRx storage
    // The generic search need column definition now
    if (!(this.colDefinition || []).length && !isNullOrUndefined(this.paginationRequestParams)) {
      this.colDefArray = this.onSetToLabelToSnakeCase();
    }
  }

  ngOnDestroy(): void {
    this.genericSearchSubscription$.unsubscribe();
  }

  /** Fetch new searches
   * @param {LookupParams} queryParams */
  public onSearch(queryParams: LookupParams): void {
    if (!isNullOrUndefined(queryParams)) {
      this.query = queryParams.query;
      this.targetValue = this.query;
      this.paginationRequestParams.size = 10;
      this.paginationRequestParams.q = queryParams.query;
      this.paginationRequestParams.page = queryParams.page;
      this.paginationRequestParams.split = this.filterBySplit;
      this.store.dispatch(setResponsePending({ isResponsePending: true }));
      this.store.dispatch(
        fetchSearchGenericItems({
          typeFunction: this.typeEntitySearch,
          paginationRequestParams: this.paginationRequestParams,
          serviceUrl: this.serviceUrl,
        }),
      );
    }
  }

  /**
   * Emit value selected
   * @param event
   */
  public onSelectionEvent(event: any): void {
    this.wasSelected = true;
    // this event is emitted when any item is selected.
    // WARN: selected validation work for single select and not work for multiple selections
    // for multiple selection use internalSelection
    let itemSelection = event.value;
    if (this.multiple) {
      itemSelection = this.internalSelection.map(res => res.value);
    }
    this.onSelected.emit(itemSelection);
  }

  // for multi and single selection features, the validation it's complicated
  // so, correct validations is do it through wasSelected variable
  // CAUTION: validate onSelected output event on component root emitter
  public onBlurEvent(event: any): void {
    // CAUTION: event target work only for single selection
    if (!this.multiple && this.wasSelected) {
      const value = event.target?.value;
      if (value && this.internalSelection && this.internalSelection.length > 0) {
        const expectedValue = this.internalSelection ? this.internalSelection[0].label : '';
        // go back to the last set when we don't match selection
        if (value !== expectedValue) {
          this.internalSelection = undefined;
          this.onSelected.emit(undefined);
          this.wasSelected = false;
          event.target.value = ''; // clear invalid selection
        }
      } else {
        // kill existing selection for empty
        this.internalSelection = undefined;
        this.onSelected.emit(undefined);
      }
      this.wasSelected = false;
    }
    this.onBlur(event);
  }

  /** setting format for display value selected
   * @param item - current value
   * @return {SelectItem} selectItem by primeNg model */
  private modelToSelectItem(item: any): SelectItem {
    let displayStr: string = this.getDisplayValue(item);
    if (displayStr == undefined) displayStr = '';
    // apply same format as query result for display value
    displayStr = this.formatDisplay(displayStr);
    return displayStr === 'undefined'
      ? <SelectItem>{ label: '', value: '' }
      : <SelectItem>{
          value: item,
          label: displayStr,
        };
  }

  /** similar onSelect event. This only multi selection mode and fire up before that onSelection event
   * @param event - primeNg model Event
   * @return Output changes emitting
   */
  public onChangeNgModel(event: any): void {
    let extractValue: any;
    if (this.multiple && event) {
      this.internalSelection = event;
      extractValue = event.map(evt => evt.value);
      this.wasSelected = false;
    } else {
      this.internalSelection = [event];
      extractValue = event.value;
    }
    this.onChanges(extractValue);
  }

  /** Emit unSelection item (multi-selection only)
   * @param event - primeNg event */
  public onUnSelection(event: any): void {
    this.onUnSelected.emit(event.value);
  }

  /** Formatting data displayed
   * @return {string} - Data formatted */
  public customResult(obj: any): string {
    const value = hasOwnProperty(obj, 'label');
    let str = value ? obj.label : obj; // no property is a string array
    if (!this.query) {
      return str;
    }
    const reg = new RegExp(`(^|\\W)(${replaceStringForRegexp(this.query)})`, 'gi');
    str = str.replace(reg, '$1<b>$2</b>');
    // apply a format as SelectItem
    str = this.formatDisplay(str);
    return str;
  }

  /** Clean customizable format
   * @return {string} string cleaning */
  public formatDisplay(str: string): string {
    // CAUTION: the input component will always remove some characters
    // like line breaks from the selected (display) value.
    // So we better remove these characters. Otherwise,
    // the selected value could be different
    // from the internal selection.
    const reg = /\r?\n/g;
    str = str.replace(reg, ' ');
    return str;
  }

  /**
   * Emit the page changes on grid
   * @param event {number}
   */
  public changePageGrid(event: number): void {
    this.lastTargetValue = isNotEmpty(this.targetValue) ? this.targetValue : this.lastTargetValue;
    this.onSearch({ query: this.lastTargetValue, page: event });
  }

  /** Format a custom display (optional)
   * @param value
   */
  private getDisplayValue(value = {}): string {
    if (typeof value === 'string') {
      return value;
    }
    let customValue = '';
    // with magnifier features
    if (nullsafe(this.fieldDisplay).length && nullsafe(this.colDefArray).length) {
      // with magnifier features
      this.fieldDisplay.map(field => {
        // Get the first item for custom display. It's challenging for generic entity
        this.colDefArray.some(() => {
          const displayCamelField = field.replace(/_\w/g, m => m[1].toUpperCase());
          customValue += `${value[displayCamelField]}`;
          return true;
        });
      });
    } else {
      // only autocomplete
      customValue = !isNullOrUndefined(this.fieldDisplay) ? value[this.fieldDisplay[0]] : '';
    }
    return (customValue || '').trim();
  }

  /** Get result lookup's grid and setting internal selection value
   * @param event Entity type of selection result
   */
  public getResultSelections(event: any): void {
    if (this.multiple) {
      this.internalSelection = <SelectItem[]>event.map(
        (row: any) =>
          <SelectItem>{
            value: row,
            label: this.getDisplayValue(row),
          },
      );
    } else {
      this.internalSelection = [].concat({
        value: event,
        label: this.getDisplayValue(event),
      });
    }
    this.onSelected.emit(event);
    this.wasSelected = true;
    this.onBlurEvent({ target: { value: this.internalSelection[0].label } }); // force valid selection when any row is selected
  }

  /** Setting suggestions when makes new searches */
  private storeToProps(): Subscription {
    return this.store.select(fromRoot.getGenericSearchItems).subscribe(items => {
      if (items && items !== this.searchResult) {
        this.searchResult = items;
        const suggestion = [].concat(<any[]>items.items);
        this.suggestions = suggestion.map(
          sugg =>
            <
              SelectItem // setting custom display
            >{
              label: this.getDisplayValue(sugg),
              value: sugg,
            },
        );
        this.pagination = <PaginationMetadata>items.__pagination;
        this.cd.markForCheck();
      } else {
        this.searchResult = undefined;
        this.pagination = undefined;
        this.suggestions = undefined;
      }
    });
  }

  public onFocusEvent(): void {
    this.onFocus.emit();
  }

  // stop keyboard ESCAPE event everywhere
  // into masters-data, keyboard escape event cause exit edit mode
  @HostListener('document:keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' && this.displayGrid) {
      event.stopPropagation();
    }
  }

  private onSetToLabelToSnakeCase(): string[] {
    return this.onGetColumns(this.paginationRequestParams.columns).map((item: string) =>
      item.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase(),
    );
  }

  private splitColumns(varToSplit: string): string[] {
    if (varToSplit.trim().length) return varToSplit.split(',');
    return [];
  }

  private onGetColumns(stringToSplit: string): string[] {
    const mySplit = this.splitColumns(stringToSplit);
    return (mySplit || []).map(item => item.split('.')[0]);
  }

  public onClickMagnifierBtn(): void {
    this.suggestions = [];
    this.displayGrid = true;
  }
}
