import { NgModule } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PickListModule } from 'primeng/picklist';
import { GalleriaModule } from 'primeng/galleria';
import { FileUploadModule } from 'primeng/fileupload';
import { SliderModule } from 'primeng/slider';
import { TabViewModule } from 'primeng/tabview';
import { EditorModule } from 'primeng/editor';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { UrlSafePipe } from './pipes/url-safe-pipe';
import { EmailFormComponent } from './email-form/email-form.component';
import { SliderComponent } from './slider/slider.component';
import { FileUploadBasicComponent } from './file-upload-basic/file-upload-basic.component';
import { PickListBasicComponent } from './pick-list-basic/pick-list-basic.component';
import { NcsChipsComponent } from './chips/chips.component';
import { NcsMultiSelectComponent } from './multiselect/multiselect.component';
import { SelectItemPipePipe } from './pipes/select-item-pipe.pipe';
import { NcsSpinner } from './spinner/spinner.component';
import { NcsSelectButtonComponent } from './select-button/select-button.component';
import { FileUploadComponent } from './upload/file-upload.component';
import { SharedBasicModule } from './basic-shared-module/shared-basic.module';

/**
 * This module declares common custom components, pipes, etc. that are used throughout the application.
 *
 * This module also re-exports some external modules for common use, like Angular CommonsModule and FormsModule,
 * the TranslateModule and several PrimeNG modules.
 */
@NgModule({
  imports: [
    DialogModule,
    TranslateModule,
    CommonModule,
    SharedBasicModule,
    InputTextModule,
    ChipsModule,
    FormsModule,
    MultiSelectModule,
    SelectButtonModule,
    ProgressSpinnerModule,
    PickListModule,
    GalleriaModule,
    FileUploadModule,
    SliderModule,
    TabViewModule,
    EditorModule,
    ConfirmDialogModule,
  ],
  declarations: [
    FileUploadComponent,
    NcsChipsComponent,
    NcsMultiSelectComponent,
    NcsSelectButtonComponent,
    NcsSpinner,
    SelectItemPipePipe,
    PickListBasicComponent,
    FileUploadBasicComponent,
    SliderComponent,
    EmailFormComponent,
    UrlSafePipe,
  ],
  exports: [
    PickListBasicComponent,
    FileUploadComponent,
    NcsChipsComponent,
    NcsMultiSelectComponent,
    NcsSelectButtonComponent,
    NcsSpinner,
    SliderComponent,
    SelectItemPipePipe,
    FileUploadBasicComponent,
    EmailFormComponent,
    UrlSafePipe,
  ],
})
export class SharedModule {}
