import { isNullOrUndefined } from './object-utils';

export function isNotEmpty(value: string): boolean {
  return !isNullOrUndefined(value) && value?.trim().length > 0;
}

export function isEmpty(value: string): boolean {
  return !isNotEmpty(value);
}

export function padZeros(value: string, max: number): string {
  if (isNullOrUndefined(value)) return value;

  value = value.trim();
  return value.length >= max ? value : `0${padZeros(value, max - 1)}`;
}

export function notNullOrUndefined(value: string, def: string): string {
  if (isNullOrUndefined(value)) return def;
  return value;
}
