import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TooltipAttribute } from '../renderer/number-renderer.component';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';

@Component({
  selector: 'text-tooltip-cell-renderer',
  template: `
    <nkg-tooltip [tooltipSeverity]="tooltip?.severity">
      <nkg-tooltip-target>
        <span>{{ value }}</span>
      </nkg-tooltip-target>
      <nkg-tooltip-content *ngIf="valueAsHtml">
        <span [innerHtml]="valueAsHtml"></span>
      </nkg-tooltip-content>
    </nkg-tooltip>
  `,
})
export class TextTooltipCellRendererComponent implements AgRendererComponent {
  value: string;
  valueAsHtml: SafeHtml;
  tooltip: TooltipAttribute;
  tooltipFunction: (params: ICellRendererParams) => TooltipAttribute;

  constructor(private sanitizer: DomSanitizer) {}

  agInit(params: ICellRendererParams): void {
    if (params.colDef.cellRendererParams) {
      if (params.colDef.cellRendererParams.tooltipFunction)
        this.tooltipFunction = params.colDef.cellRendererParams.tooltipFunction;
    }
    this.formatValue(params);
  }

  refresh(params: any): boolean {
    this.formatValue(params);
    return true;
  }

  formatValue(params: ICellRendererParams): void {
    this.value = params.getValue();
    if (this.tooltipFunction) {
      this.valueAsHtml = !isNullOrUndefined(this.value)
        ? this.sanitizer.bypassSecurityTrustHtml(this.value.replace(/\n/g, '<br/>'))
        : undefined;
      this.tooltip = this.tooltipFunction(params);
    }
  }
}
