<div class="col-12">
  <p-table
    #dt
    styleClass="p-table-crud"
    [columns]="cols"
    [value]="items"
    [selectionMode]="selectionMode"
    [(selection)]="selectionMode === 'single' ? selectedItem : selectedItems"
    [paginator]="paginator"
    [loading]="loading"
    [rows]="rows"
    [resizableColumns]="true"
    [reorderableColumns]="true"
    [autoLayout]="autoLayout"
    [rowHover]="true"
    [sortMode]="">
    <ng-template pTemplate="caption">
      <div class="grid" *ngIf="addEnable || exportCsv || isRefresh">
        <div class="col-6 flex justify-content-start flex-wrap">
          <span *ngIf="editMode && addEnable">
            <button type="button" pButton icon="pi pi-plus" (click)="showDialogToAdd()"></button>
          </span>
          <span *ngIf="isRefresh && !editMode">
            <button type="button" pButton icon="pi pi-refresh" (click)="refreshData()"></button>
          </span>
        </div>
        <div class="col-6 flex justify-content-end flex-wrap">
          <span class="p-helper-clearfix" *ngIf="!!items && items.length > 0 && !editMode && exportCsv">
            <button
              style="font-size: 11px"
              type="button"
              pButton
              icon="pi pi-download"
              class="p-button-raised"
              iconPos="left"
              label="CSV"
              (click)="exportToCsv(dt)"></button>
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns *ngIf="showTable">
      <tr>
        <th scope="col" *ngIf="checkbox">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th
          scope="col"
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          [pSortableColumnDisabled]="pSortableColumnDisabled">
          {{ col.header | translate }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
        <th
          scope="col"
          class="manage-btns-header"
          *ngIf="!editMode && showEnable && !!items && items.length > 0"
          style="width: 40px"></th>
        <th scope="col" class="manage-btns-header" *ngIf="editMode && editEnable" style="width: 40px">-</th>
        <th scope="col" class="manage-btns-header" *ngIf="editMode && removeEnable" style="width: 40px">-</th>
        <th
          scope="col"
          class="manage-btns-header"
          *ngIf="!editMode && itemTemplate && !!items && items.length > 0"
          style="width: 40px"></th>
        <th
          scope="col"
          class="manage-btns-header"
          *ngIf="!editMode && itemTemplate2 && !!items && items.length > 0"
          style="width: 40px"></th>
        <th
          scope="col"
          class="manage-btns-header"
          *ngIf="!editMode && itemTemplate3 && !!items && items.length > 0"
          style="width: 40px"></th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-rowData
      let-columns="columns"
      class="p-resizable-column"
      let-rowIndex="rowIndex"
      *ngIf="showTable">
      <tr
        [pSelectableRow]="rowData"
        [pSelectableRowIndex]="rowIndex"
        [ngClass]="{ 'p-selected-row': selectedItem?.id == rowData?.id }"
        (click)="rowSelected(rowData)"
        *ngIf="!rowData?.deleted || showItemsDeleted">
        <td style="text-align: center; font-size: smaller" *ngIf="checkbox">
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns" class="{{ col?.cssClass }}">
          {{ getCellData(rowData, col) }}
        </td>
        <td *ngIf="!editMode && showEnable" class="manage-btns">
          <button
            pButton
            type="button"
            class="p-button-raised p-button-secondary"
            icon="pi pi-external-link"
            style="font-size: 10px; background: darkgreen"
            (click)="onRowSelect(rowData)"></button>
        </td>
        <td *ngIf="editMode && editEnable" class="manage-btns">
          <button
            pButton
            type="button"
            class="p-button-raised p-button-secondary"
            icon="pi pi-pencil"
            style="font-size: 10px; background: orange"
            (click)="onStartEdition(rowData)"></button>
        </td>
        <td *ngIf="editMode && removeEnable" class="manage-btns">
          <button
            pButton
            type="button"
            class="p-button-raised p-button-danger"
            icon="pi pi-trash"
            style="font-size: 10px; background: orangered"
            (click)="delete(rowData)"></button>
        </td>
        <td *ngIf="!editMode && itemTemplate" style="width: 40px">
          <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: rowData }"></ng-container>
        </td>
        <td *ngIf="!editMode && itemTemplate2" style="width: 40px">
          <ng-container *ngTemplateOutlet="itemTemplate2; context: { $implicit: rowData }"></ng-container>
        </td>
        <td *ngIf="!editMode && itemTemplate3" style="width: 40px">
          <ng-container *ngTemplateOutlet="itemTemplate3; context: { $implicit: rowData }"></ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns *ngIf="showTable">
      <tr *ngIf="!loading">
        <td [attr.colspan]="columns.length + (editMode ? editEnable + removeEnable : 1)">
          {{ 'grid.messages.no_rows_found' | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- Dialog form -->
<ncs-dialog-basic
  [appendTo]="dt"
  [primaryButtonShow]="editMode"
  [headerText]="headerDialog"
  [visible]="isDisplayDialog"
  (onHide)="cancel()"
  (onSecondaryClicked)="cancel()"
  [secondaryButtonText]="'common.buttons.close'"
  [showOnModal]="showOnModal"
  [styleDialog]="dialogFormStyle"
  [primaryButtonText]="'common.buttons.save'"
  (onPrimaryClicked)="save()">
  <ng-content select="[dialog-form=form]"></ng-content>
</ncs-dialog-basic>
