import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { ColDef, GridOptions } from 'ag-grid-community';
import { SelectItem } from 'primeng/api';
import { CuppingProcessService } from '../../../cupping-process/services/cupping-process.service';
import {
  CupEvaluator,
  CuppingProcess,
  CuppingProcessType,
  CuppingSession,
  CuppingSessionCupEvaluator,
  MetricsDefinitionType,
  Permission,
  User,
} from '../../../../../../../../../../core/domain/models';
import { Rule, Validation, ValidationResult } from '../../../../../../../../services/master-validation';
import { NkgGridFactory } from '../../../../../../../../../../shared/grid/grid.factory';
import { CuppingSessionService } from '../../../../../../services/cupping-sesion.service';
import { MasterdataValidationService } from '../../../../../../../../services/masterdata-validation.service';
import {
  hasOwnProperty,
  isNullOrUndefined,
  mergeImmutable,
  nullsafe,
} from '../../../../../../../../../utils/object-utils';
import { extractEvent } from '../../../../../../../../../utils/extract-event';
import { fetchCupEvaluators } from '../../../../../../../../../../core/ngrx/actions';
import * as fromRoot from '../../../../../../../../../../core/ngrx/reducers';

@Component({
  selector: 'app-enter-cupping-session',
  templateUrl: './enter-to-cupping-session.component.html',
  providers: [CuppingProcessService],
})
export class EnterToCuppingSession implements OnDestroy {
  @Input() cuppingSessionId: number;
  @Input() cuppingProcessType: CuppingProcessType;
  @Input() visibleDialog: boolean = false;
  cuppingSession: CuppingSession;
  cupEvaluators: SelectItem[];
  childValidationResult: ValidationResult;
  currentUser: User;
  dialogEnterCuppingProcessSubscription$: Subscription;
  showButtonSession: boolean = false;
  gridOptionsEnterCupping: GridOptions;
  columnDefsCuppingProcess: ColDef[];
  cuppingProcessesRowData: CuppingProcess[] = [];
  selectMetricType: MetricsDefinitionType;
  cupEvaluatorSelected: CupEvaluator;
  permission = Permission;
  isEditAllCuppingPermission: boolean = false;
  cupEvaluatorSuggestions: SelectItem[] = [];

  constructor(
    protected store: Store<fromRoot.State>,
    protected gf: NkgGridFactory,
    protected router: Router,
    protected route: ActivatedRoute,
    protected cuppingSessionService: CuppingSessionService,
    protected validationService: MasterdataValidationService,
  ) {
    this.dialogEnterCuppingProcessSubscription$ = this.buttonCuppingProcessSubscription();
    this.gridOptionsEnterCupping = this.gf.getDefaultGridOptions(true);
    this.columnDefsCuppingProcess = this.getColumnDefsCuppingProcess();
  }

  buttonCuppingProcessSubscription(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getCurrentUser),
      this.store.select(fromRoot.getCuppingSession),
      this.store.select(fromRoot.getCupEvaluators),
    ]).subscribe(([currentUser, cuppingSession]) => {
      this.currentUser = currentUser;
      if (!isNullOrUndefined(this.currentUser)) {
        this.showButtonSession = this.currentUser.hasPermissions([this.permission.EDIT_CUPPING_PROCESS]);
        this.isEditAllCuppingPermission = this.currentUser.hasPermissions([this.permission.EDIT_ALL_CUPPING_PROCESS]);
      }
      if (!isNullOrUndefined(cuppingSession) && nullsafe(cuppingSession.metricsDefinitionTypes).length > 0) {
        // Assume that metricsDefinitionTypes[0] should be a sensorial type
        this.selectMetricType = cuppingSession.metricsDefinitionTypes[0];
        this.cuppingSession = cuppingSession;
      }
      this.loadCupEvaluatorsBySession();
    });
  }

  // WARNING: The CupEvaluator's tenant must be same tenant that any cupping session.
  // If any shared tenant was removed from cup eval, her will still appear into
  // Cup Eval session's list, although he does not belong to same tenant.
  private loadCupEvaluatorSelection(cuppingSession: CuppingSession): void {
    if (!isNullOrUndefined(this.currentUser) && !isNullOrUndefined(cuppingSession)) {
      const cuppingEvaluators: CuppingSessionCupEvaluator[] = cuppingSession.cuppingSessionCupEvaluators || [];
      const sessionEvaluator: CuppingSessionCupEvaluator = cuppingEvaluators.find(
        item =>
          hasOwnProperty(item.cupEvaluator, 'cupEvaluatorUser') &&
          item.cupEvaluator.cupEvaluatorUser.idUser == this.currentUser.id,
      );
      this.cuppingProcessesRowData = this.loadCuppingProcessRowData(cuppingSession, sessionEvaluator?.cupEvaluator);
      /** There must be at least one cupEvaluator */
      this.cupEvaluatorSelected = sessionEvaluator?.cupEvaluator || (cuppingEvaluators || [])[0].cupEvaluator;
      this.onItemFieldBlur('cupEvaluator');
    }
  }
  /** Filter cupEvaluators options by CuppingSession
   * Only get cuppingSession's cupEvaluators */
  private loadCupEvaluatorsBySession(): void {
    if (!isNullOrUndefined(this.cuppingSession)) {
      if (
        (this.cuppingProcessType == CuppingProcessType.CUPPING_SESSION ||
          this.cuppingProcessType == CuppingProcessType.DASHBOARD) &&
        this.isEditAllCuppingPermission
      ) {
        this.cupEvaluators = [];
        (this.cuppingSession.cuppingSessionCupEvaluators || []).map(sessionEval => {
          if (sessionEval && sessionEval.cupEvaluator) {
            this.cupEvaluators.push({
              label: sessionEval.cupEvaluator.cupEvaluator,
              value: sessionEval.cupEvaluator,
            });
          }
        });
        this.cupEvaluators.sort((a, b) => (a.label < b.label ? -1 : 1));
      }
    }
  }

  ngOnDestroy(): void {
    this.dialogEnterCuppingProcessSubscription$.unsubscribe();
  }

  public onHideDialog(): void {
    this.visibleDialog = false;
  }

  public onShowCuppingProcessDialog(): void {
    const gridOptValid = this.gridOptionsEnterCupping && this.gridOptionsEnterCupping.api;
    if (!this.cuppingProcessesExists() && gridOptValid) {
      this.gridOptionsEnterCupping.api.setRowData([]);
    } else if (gridOptValid) {
      this.gridOptionsEnterCupping.api.setRowData(this.cuppingProcessesRowData);
    }
    this.visibleDialog = true;
  }

  public onClickedButton(): void {
    if (
      this.cuppingProcessType == CuppingProcessType.CUPPING_PROCESS ||
      this.cuppingProcessType == CuppingProcessType.SAMPLE
    ) {
      return this.store.dispatch(fetchCupEvaluators({ userDefaultTenantId: this.currentUser?.defaultTenant?.id }));
    }
    this.loadCupEvaluatorsBySession();
    this.loadCupEvaluatorSelection(this.cuppingSession);
    this.onShowCuppingProcessDialog();
  }

  loadCuppingProcessRowData(cuppingSession: CuppingSession, cupEvaluator?: CupEvaluator): CuppingProcess[] {
    if (!isNullOrUndefined(this.cuppingSession)) {
      if (this.isEditAllCuppingPermission) return this.cuppingSession.cuppingProcesses || [];
      const evaluatorId = cupEvaluator && cupEvaluator.id;
      // case 1: Any admin can select any cup evaluator
      // case 2: If only cup evaluator, filtered sessionEval by this
      if (!isNullOrUndefined(evaluatorId)) {
        return (this.cuppingSession.cuppingProcesses || []).filter(
          x => x.cupEvaluator != null && x.cupEvaluator.id == evaluatorId,
        );
      }
    }
    return [];
  }

  /** Redirect to CuppingSession component */
  public onEnterToSession(): void {
    this.childValidationResult = this.validationService.validateItem(
      { cupEvaluator: this.cupEvaluatorSelected },
      this.getRuleValidation(),
    );
    if (!this.childValidationResult.valid) {
      return;
    }

    const cuppingSessionCupEvaluator: CuppingSessionCupEvaluator = this.cuppingSession.cuppingSessionCupEvaluators.find(
      x => x.cupEvaluator.id == this.cupEvaluatorSelected.id,
    );

    setTimeout(() => {
      if (
        !isNullOrUndefined(this.cupEvaluatorSelected) &&
        !isNullOrUndefined(this.selectMetricType) &&
        !isNullOrUndefined(this.cuppingSession)
      ) {
        const url = this.router.createUrlTree(['/master/general/cupping-process/'], {
          relativeTo: this.route,
          queryParams: {
            cupping_session_id: this.cuppingSession.id,
            sample_id: this.cuppingSession.cuppingSessionSamples.find(x => x.position == 0).sample.id,
            position: 0,
            cup_evaluator_id: this.cupEvaluatorSelected.id,
            metrics_definition_type_id: this.selectMetricType.id,
            type: this.cuppingProcessType,
            sample_weight: isNullOrUndefined(this.cuppingSession.sampleWeight) ? 0 : this.cuppingSession.sampleWeight,
            cups: this.cuppingSession.totalCups || this.cuppingSession.standardDefinition.totalCups,
            image: this.cuppingSession.cupIllustration ? this.cuppingSession.cupIllustration.id : '',
            to_average: isNullOrUndefined(cuppingSessionCupEvaluator) ? false : cuppingSessionCupEvaluator.valid,
            onlyContent: 1,
          },
        });
        this.onHideDialog();
        window.open(`#${url.toString()}`, '_blank');
      }
    });
  }

  /** Check if exist any cupping */
  private cuppingProcessesExists(): boolean {
    return !!nullsafe(this.cuppingProcessesRowData).length;
  }

  protected getColumnDefsCuppingProcess(): ColDef[] {
    return this.cuppingSessionService.loadCuppingProcessColDef();
  }

  /** @param cuppingProcess {CuppingProcess} redirect to cupping-process component for a new cupping */
  public rowDoubleClicked(cuppingProcess: CuppingProcess): void {
    const url = this.router.createUrlTree(['/master/general/cupping-process/'], {
      relativeTo: this.route,
      queryParams: {
        cupping_process_id: cuppingProcess.id,
        type: this.cuppingProcessType,
        onlyContent: 1,
      },
    });

    window.open(`#${url.toString()}`, '_blank');
  }

  protected getRuleValidation(): Rule {
    return {
      cupEvaluator: (v: Validation): void => {
        if (!v.ctx) {
          v.error('common.validation.empty_field_not_allowed');
        }
      },
    };
  }

  public onItemFieldChange(event: any, fld?: string): void {
    const upd = {};
    upd[fld] = extractEvent(event);
    this.cupEvaluatorSelected = upd[fld];
    let newValidationResult: ValidationResult = this.validationService.clearFieldResults(
      this.childValidationResult,
      fld,
    );
    newValidationResult = mergeImmutable(
      newValidationResult,
      this.validationService.validateItem({ cupEvaluator: this.cupEvaluatorSelected }, this.getRuleValidation(), fld),
    );
    this.childValidationResult = newValidationResult;
  }

  public onItemFieldBlur(fld: any): void {
    let newValidationResult: ValidationResult = this.validationService.clearFieldResults(
      this.childValidationResult,
      fld,
    );
    newValidationResult = mergeImmutable(
      newValidationResult,
      this.validationService.validateItem({ cupEvaluator: this.cupEvaluatorSelected }, this.getRuleValidation(), fld),
    );
    this.childValidationResult = newValidationResult;
  }

  public onLoadSuggestions(query: string): void {
    query = query.toLowerCase();
    const filtered = [];
    nullsafe(this.cupEvaluators).map(cupEval => {
      const comparison = cupEval.label.toLowerCase();
      if (comparison.indexOf(query) >= 0) {
        filtered.push(cupEval);
      }
    });
    this.cupEvaluatorSuggestions = filtered;
  }
}
